<template>
  <div>
    <el-row>
      <el-col :span="24" style="margin-bottom: 30px">
        <div>
          <el-date-picker style="margin-left: 20px" @change="swchange" v-model="getTime" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <!-- 统计图表 -->
        <Line-Chart v-if="chartStatus" :overDay="overDay" :sos-line-data="sosLineData" :height="'300px'" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div class="statisticsBox">
          <div class="disFlex">
            <h3>电梯报警统计</h3>
            <div class="operatTypeolor">
              <span style="margin-left: 15px;cursor: pointer;" @click="changeSOSType('all')"
                :style="{'color': sosType == 'all' ? '#00f6ff !important' : '#c9deff !important'}">汇总</span>
              <span style="margin-left: 15px;cursor: pointer;" @click="changeSOSType('day')"
                :style="{'color': sosType == 'day' ? '#00f6ff !important' : '#c9deff !important'}">当日</span>
            </div>
          </div>
          <div class="disFlex" style="align-items: flex-start;">
            <div style="width: 50%; padding: 0 15px;">
              <SosPieChart v-if="sosPieDataType" :width="'100%'" :height="'250px'" :sosPieData="sosTypeArr"
                :trendColor="trendColor" />
            </div>
            <div style="width: 50%; padding: 0 15px;" v-if="sosTypeArr != []">
              <div class="disFlex sosBox">
                <div style="width: 75%">报警名称</div>
                <div style="width: 30%" class="sosCount">次数</div>
              </div>
              <div v-for="(item, index) in sosTypeArr" :key="item.sosName" class="disFlex sosBox">
                <div style="width: 75%">TOP{{index + 1}} {{item.sosName}}</div>
                <div style="width: 30%" class="sosCount">{{item.sosCount}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="statisticsBox" style="padding: 10px 25px; margin: 10px 0 30px 15px;">
          <div class="disFlex" v-if="operatArr">
            <h3>运行强度</h3>
            <div class="operatTypeolor">
              <span style="margin-left: 15px;cursor: pointer;" @click="changeOperatingype('all')"
                :style="{'color': operatType == 'all' ? '#00f6ff !important' : '#c9deff !important'}">汇总</span>
              <span style="margin-left: 15px;cursor: pointer;" @click="changeOperatingype('day')"
                :style="{'color': operatType == 'day' ? '#00f6ff !important' : '#c9deff !important'}">昨日</span>
              <!-- <el-button type="text" @click="changeOperatingype('all')" :style="{'color': operatType == 'all' ? '#00f6ff !important' : '#fff !important'}">汇总</el-button>
              <el-button type="text" @click="changeOperatingype('day')" :style="{'color': operatType == 'day' ? '#00f6ff !important' : '#fff !important'}">昨日</el-button> -->
            </div>
          </div>
          <div v-if="operatData">
            <div class="disFlex sosBox" style="width: 100%">
              <div style="width: 60%">类型</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;">汇总数据</div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;">昨日</div>
                <div style="width: 20%; text-align: center;">上一日环比</div>
              </template>
            </div>
            <div class="disFlex sosBox">
              <div style="width: 60%">运行里程（km）</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;" v-if="operatArr.collect">{{operatArr.collect.distance}}
                </div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;" v-if="operatArr.previousDay">
                  {{operatArr.previousDay.distance}}</div>
                <div style="width: 20%; text-align: center;" v-if="operatArr.ringRatio">
                  {{ (operatArr.ringRatio.distance * 100).toFixed(0)}}%</div>
              </template>
            </div>
            <div class="disFlex sosBox">
              <div style="width: 60%">运行时间（小时）</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;" v-if="operatArr.collect">{{operatArr.collect.runningTime}}
                </div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;" v-if="operatArr.previousDay">
                  {{operatArr.previousDay.runningTime}}</div>
                <div style="width: 20%; text-align: center;" v-if="operatArr.ringRatio">
                  {{(operatArr.ringRatio.runningTime * 100).toFixed(0) }}%
                </div>
              </template>
            </div>
            <div class="disFlex sosBox">
              <div style="width: 60%">运行次数（次）</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;" v-if="operatArr.collect">{{operatArr.collect.runningNum}}
                </div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;" v-if="operatArr.previousDay">
                  {{operatArr.previousDay.runningNum}}</div>
                <div style="width: 20%; text-align: center;" v-if="operatArr.ringRatio">
                  {{(operatArr.ringRatio.runningNum * 100).toFixed(0) }}%
                </div>
              </template>
            </div>
            <div class="disFlex sosBox">
              <div style="width: 60%">载人数（人）</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;" v-if="operatArr.collect">{{operatArr.collect.head}}</div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;" v-if="operatArr.previousDay">{{operatArr.previousDay.head}}
                </div>
                <div style="width: 20%; text-align: center;" v-if="operatArr.ringRatio">
                  {{(operatArr.ringRatio.head * 100).toFixed(0) }}%</div>
              </template>
            </div>
            <div class="disFlex sosBox">
              <div style="width: 60%">开关门次数（次）</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;" v-if="operatArr.collect">{{operatArr.collect.doorOpen}}
                </div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;" v-if="operatArr.previousDay">
                  {{operatArr.previousDay.doorOpen}}</div>
                <div style="width: 20%; text-align: center;" v-if="operatArr.ringRatio">
                  {{(operatArr.ringRatio.doorOpen * 100).toFixed(0) }}%</div>
              </template>
            </div>
            <div class="disFlex sosBox">
              <div style="width: 60%">折弯次数（次）</div>
              <template v-if="operatType == 'all'">
                <div style="width: 20%; text-align: center;" v-if="operatArr.collect">{{operatArr.collect.bendNum}}
                </div>
              </template>
              <template v-else>
                <div style="width: 20%; text-align: center;" v-if="operatArr.previousDay">
                  {{operatArr.previousDay.bendNum}}</div>
                <div style="width: 20%; text-align: center;" v-if="operatArr.ringRatio">
                  {{(operatArr.ringRatio.bendNum * 100).toFixed(0) }}%</div>
              </template>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <h3 style="margin-top:0;" class="changepointer">电梯数据（{{sosLineData.date[0]}} -
        {{sosLineData.date[sosLineData.date.length - 1]}}）
      </h3>
      <el-descriptions class="descriptionsType" :column="4" border>
        <el-descriptions-item label="运行次数"
          label-class-name="myLabel">{{ statisticsInfo.runningNum }}次</el-descriptions-item>
        <el-descriptions-item label="运行里程"
          label-class-name="myLabel">{{ statisticsInfo.distance > 1000 ? `${(statisticsInfo.distance / 1000).toFixed(2)} km` : `${statisticsInfo.distance} m` }}</el-descriptions-item>
        <el-descriptions-item label="运行时间"
          label-class-name="myLabel">{{ statisticsInfo.runningTime }}</el-descriptions-item>
        <el-descriptions-item label="震动次数"
          label-class-name="myLabel">{{ statisticsInfo.shakeNum }}次</el-descriptions-item>
        <el-descriptions-item v-if="adminInfo.roleId != 99" label="主动报警"
          label-class-name="myLabel">{{ statisticsInfo.autoSos }}次</el-descriptions-item>
        <el-descriptions-item v-if="adminInfo.roleId != 99" label="语音报警"
          label-class-name="myLabel">{{ statisticsInfo.audioSos }}次</el-descriptions-item>
        <el-descriptions-item v-if="adminInfo.roleId != 99" label="安全回路报警"
          label-class-name="myLabel">{{ statisticsInfo.security }}次</el-descriptions-item>
        <el-descriptions-item label="困人报警"
          label-class-name="myLabel">{{ statisticsInfo.stuckSos }}次</el-descriptions-item>
        <el-descriptions-item label="开关门次数"
          label-class-name="myLabel">{{ statisticsInfo.openDoorNum }}次</el-descriptions-item>

      </el-descriptions>
    </el-row>
    <el-row>
      <h3 class="changepointer">电梯累计数据</h3>
      <el-descriptions class="descriptionsType" :column="4" border>
        <el-descriptions-item label="总运行次数"
          label-class-name="myLabel">{{ statisticsInfo.runningNumTotal }}次</el-descriptions-item>
        <el-descriptions-item label="总运行里程" label-class-name="myLabel">
          {{ statisticsInfo.distanceTotal > 1000 ? `${(statisticsInfo.distanceTotal / 1000).toFixed(2)} km` : `${statisticsInfo.distanceTotal} m` }}</el-descriptions-item>
        <el-descriptions-item label="总运行时长"
          label-class-name="myLabel">{{ statisticsInfo.runningTimeTotal }}</el-descriptions-item>
        <el-descriptions-item label="总在线时长"
          label-class-name="myLabel">{{ statisticsInfo.onlineTimeTotal }}</el-descriptions-item>

      </el-descriptions>
    </el-row>
  </div>
</template>

<script>
  import LineChart from './lineChart'
  import SosPieChart from './sosPieChart';
  import {
    formatDate
  } from "@/util";
  let getTimeFif = [new Date(new Date(new Date().toLocaleDateString()).getTime() - 15 * 24 * 3600 * 1000).getTime(),
    new Date(new Date(new Date().toLocaleDateString()).getTime() - 1000).getTime()
  ]
  let getTimeNin = [new Date(new Date(new Date().toLocaleDateString()).getTime() - 90 * 24 * 3600 * 1000).getTime(),
    new Date(new Date(new Date().toLocaleDateString()).getTime() - 1000).getTime()
  ]
  export default {
    components: {
      LineChart,
      SosPieChart
    },

    props: {
      elevatorId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        visible: false,
        loading: true,
        chartStatus: false,
        // 默认选择最近一周的时间戳
        getTime: [],
        getOldTime: [
          new Date(
            new Date(new Date().toLocaleDateString()).getTime() -
            15 * 24 * 3600 * 1000
          ).getTime(),
          new Date(
            new Date(new Date().toLocaleDateString()).getTime() - 1000
          ).getTime(),
        ],
        statisticsInfo: {}, // 运行详情
        sosLineData: {
          runningNum: [], // 运行次数
          runningTime: [], // 运行时长
          distance: [], // 运行距离
          date: [], // 日期
        },

        sosTypeArr: [],
        sosType: 'all',
        sosList: [],
        sosPieDataType: false,
        trendColor: ['#ff0000', '#ffd800', '#ee7ab8', '#7ae2ee', '#0014ff', '#00ff2f'],


        operatType: 'all',
        operatArr: [],
        operatData: false,

        overDay: false,
        overDayNum: 90,

        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    mounted() {
      this.getSOS()
    },
    methods: {
      // 查询时间
      getMainRecentDays() {
        this.$http
          .get(`/api/ele/web/runningNum/getMainRecentDays?eleId=${this.elevatorId}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.overDay = data < 90
              this.overDayNum = this.overDay ? 90 : 15
              this.getTime = this.overDay ? getTimeNin : getTimeFif
              this.getRunStatistical()
              this.getElevatorSosTop()
              this.getElevatorOperating()
            }
          })
      },
      // 改变统计类型
      changeSOSType(type) {
        this.sosType = type
        this.sosPieDataType = false
        this.getElevatorSosTop()
      },
      // 运行强度
      changeOperatingype(type) {
        this.operatType = type
        this.getElevatorOperating()
      },
      getSOS() {
        this.$http
          .post("/api/system/web/dict/dictionary?codes=SOS_TYPE")
          .then((res) => {
            if (res.data.success) {
              const Arr = res.data.data.SOS_TYPE.filter((item) => {
                if (this.adminInfo.userName != 'admin') {
                  return item.value != '21' && item.value != '35'
                } else {
                  return item
                }
              })
              console.log(Arr)
              this.sosList = Arr
            }
          })
      },
      getElevatorSosTop() {
        const params = {
          eleId: this.elevatorId,
          startTime: this.sosType == 'all' ? this.getTime[0] : new Date(formatDate(new Date(), "yyyy-MM-dd") +
            " 00:00:00").getTime(),
          endTime: this.sosType == 'all' ? this.getTime[1] : new Date(formatDate(new Date(), "yyyy-MM-dd") +
            " 23:59:59").getTime(),
        }
        this.$http
          .post('/api/ele/web/sos/getElevatorSosTop', params)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.sosTypeArr = []
              let sosName = ''
              if (data.length > 0) {
                data.map(item => {
                  sosName = this.sosList.find(itemList => itemList.value == item.sosType) ? this.sosList.find(
                    itemList => itemList.value == item.sosType).label : ''
                  if (this.adminInfo.roleId == 99) {
                    if (item.sosType == 5 || item.sosType == 13) {
                      this.sosTypeArr.push({
                        ...item,
                        sosName
                      })
                    }
                  } else {
                    this.sosTypeArr.push({
                      ...item,
                      sosName
                    })
                  }
                })
              }
              this.sosPieDataType = true
            }
          })
      },

      getElevatorOperating() {
        const params = {
          eleId: this.elevatorId,
          startTime: this.operatType == 'all' ? this.getTime[0] : '',
          endTime: this.operatType == 'all' ? this.getTime[1] : '',
        }
        this.$http
          .post('/api/ele/web/runningNum/getElevatorOperatingIntensity', params)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.operatArr = data
              this.operatData = true
              console.log(this.operatArr, 'data');
            }
          })
      },
      getRunStatistical() {
        this.sosLineData = {
          runningNum: [], // 运行次数
          runningTime: [], // 运行时长
          distance: [], // 运行距离
          date: [], // 日期
        }
        this.chartStatus = false
        const params = {
          eleId: this.elevatorId,
          startTime: this.formatDate(this.getTime[0]),
          endTime: this.formatDate(this.getTime[1]),
        }
        this.loading = true
        this.$http
          .post('/api/ele/web/runningNum/getRunStatistical', params)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.statisticsInfo = data.eleInfo
              data.topData.map((item) => {
                // 运行次数、运行时长、运行距离
                this.sosLineData.runningNum.push(item.runningNum)
                this.sosLineData.runningTime.push(item.runningTime)
                this.sosLineData.distance.push(item.distance)
                this.sosLineData.date.push(item.date)
              })
              this.loading = false
              this.chartStatus = true
            }
          })
      },
      // 搜索时间
      swchange() {
        const dateNum = Math.ceil(
          (this.getTime[1].getTime() - this.getTime[0].getTime() + 24 * 3600 * 1000 - 1000) /
          1000 /
          24 /
          60 /
          60
        )
        if (dateNum !== this.overDayNum) {
          this.$message.error(`查询时间${dateNum > this.overDayNum ? '最大' : '最小'}是${this.overDayNum}天`)

          this.getTime = this.getOldTime
          return
        }
        this.getRunStatistical()
      },
      // 转换时间
      formatDate(d) {
        var date = new Date(d)
        var YY = date.getFullYear() + '-'
        var MM =
          (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '-'
        var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        return YY + MM + DD
      },
    },
  }
</script>
<style lang="scss" scoped>
  .grid-hand {
    margin: 8px 0;
  }

  // /deep/.el-descriptions-item__label.is-bordered-label {
  //   background: #1282a5 !important;
  //   text-align: center;
  //   width: 135px;
  // }

  /deep/.el-descriptions-item__content {
    width: 200px;
  }

  // .statisticsBox{
  //   margin: 10px 15px 30px 0;
  //   min-height: 250px;
  //   background: #1282a5;
  //   padding: 10px 15px;
  //   border-radius: 7px;
  // }
  .disFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sosBox {
    // color: #fff;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .sosBox .sosCount {
    text-align: right;
  }
</style>
