// 安全帽
var isheart = false;
var websock = null;
var sosCarportPrompt = [];
var onlineDevice = [];
var global_callback = {}

let cuIP = window.location.host
let curIP = window.location.hostname

function initCarportSocket() { //初始化weosocket
	//ws地址
	var wsuri
	if (curIP == 'localhost' || curIP.indexOf('192.168.') !== -1) {
		wsuri = "wss://tlw-test.runde.pro/carport"; //测试测试服，第二版
	} else {
		wsuri = "wss://" + cuIP + "/carport"; //freeswitch通话
	}

	websock = new WebSocket(wsuri);
	websock.onmessage = function(e) {
		websocketonmessage(e);
	}
	websock.onclose = function(e) {
		console.log("CarportSocket死掉了");
		websocketclose(e);
	}
	websock.onopen = function() {
		websocketOpen();
	}
	//连接发生错误的回调方法
	websock.onerror = function() {
		// stopCarportHeartBeat()
		// reconnect()
		console.log("CarportSocket连接发生错误");
	}
}

function reconnect() {
	setTimeout(function() { //没连接上会一直重连，设置延迟避免请求过多
		initCarportSocket()
	}, 4000);
}

// 心跳
function heartBeat() {
	var agentData = {
		"act": "1"
	};
	if (!isheart) {
		return;
	}
	setTimeout(function() {
		sendCarportSock(agentData)
	}, 30 * 1000);
}

// 清空push_sos
function clearCarportSos(e) {
	sosCarportPrompt = []
}

//报警数据接收
function websocketonmessage(e) {
	let data = JSON.parse(e.data)
	if (data.cmd == 'carport_push_sos') {
		//sos报警
		sosCarportPrompt.push(data)
		console.log('sosCarportPrompt',sosCarportPrompt)
	} else if (data.cmd == '1') {
		// 获取在线设备
		// onlineDevice = e.data ? e.data : []

		if (isheart) {
			heartBeat();
		}
	} else if (data.hb == 'ma_set_sip_info') {
		global_callback[data.hb](data)
	} else if (data.cmd != undefined) {
		global_callback[data.cmd](data)
	}

}
//数据发送
function sendCarportSock(agentData, callback) {
	if (navigator.onLine && isheart || agentData.act == "ma_login") {
		if (agentData.act != '1') {
			global_callback[agentData.act] = callback
			if (agentData.act == 'ma_set_sip_info') {
				global_callback['ma_set_sip_info'] = callback
			}
		}
		if (websock.readyState === websock.OPEN) {
			//若是ws开启状态
			websock.send(JSON.stringify(agentData));
		} else {
			// 若未开启 ，则等待1s后重新调用
			setTimeout(function() {
				websock.send(JSON.stringify(agentData));
			}, 2 * 1000);
		}
	} else {
		console.log('网络或长连接之类的出现问题了', agentData)
	}



}

function websocketclose(e) {
	stopCarportHeartBeat()
	console.log("connection closed (" + e.code + ")");
}

function websocketOpen(e) {
	isheart = true
	heartBeat();
	console.log("连接成功");
}

//页面实际调用的方法
// 结束心跳
function stopCarportHeartBeat() {
	isheart = false;
	sessionStorage.removeItem('me_id')
	websock.close(1000)
	console.log('socket结束心跳', websock)
}

export {
	isheart,
	websock,
	clearCarportSos,
	sendCarportSock,
	onlineDevice,
	sosCarportPrompt,
	// 结束心跳
	stopCarportHeartBeat,
	initCarportSocket,
}
