<template>
  <div class="admin-project-myproject-container">
    <el-form :inline="true" :model="updateVideoSearch" class="demo-form-inline">
      <el-form-item label="日期">
        <el-date-picker v-model="updateVideoSearch.time" type="date" format="yyyy 年 MM 月 dd 日">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否有人">
        <el-select clearable v-model="updateVideoSearch.head">
          <el-option :key="1" :value="0" label="无"></el-option>
          <el-option :key="2" :value="1" label="有"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="运动状态">
        <el-select clearable v-model="updateVideoSearch.liftSpeed">
          <el-option :key="1" :value="0" label="静止"></el-option>
          <el-option :key="2" :value="1" label="运动"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchElevator" class="searchBtn">搜索</el-button>
        <el-button type="warning" @click="reast">重置</el-button>
        <!-- <el-button
          type="primary"
          @click="batchUpload"
        >批量上传</el-button> -->
      </el-form-item>
    </el-form>
    <el-table ref="updateVideoList" :data="newUpdateVideoLis" v-loading="loading" stripe fit highlight-current-row
      row-key="id" @select="handleSelectionChange" @select-all="selectAll">
      <el-table-column label="序号" type="index" width="60px" align="center" />
      <el-table-column align="center" prop="file_name" label="文件名称" min-width="150px" />
      <el-table-column align="center" label="是否有人" min-width="150px">
        <template slot-scope="scope">
          {{scope.row.head === 1 ? '有' : (scope.row.head === 0 ? '无' : '-')}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="运动状态" min-width="150px">
        <template slot-scope="scope">
          {{scope.row.liftSpeed === 1 ? '运动' : (scope.row.liftSpeed === 0 ? '静止' : '-')}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="文件上传进度" min-width="80px">
        <template slot-scope="scope">
          <span v-if="!scope.row.upload"> 未上传 </span>
          <span v-else>{{scope.row.upload}}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="address" label="操作" fixed="right" width="300px">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.upload" @click="update(scope.row.file_name, scope.row)" type="primary"
            size="small">上传
          </el-button>
          <p v-else style="margin: 7px 0" >已请求</p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {
    head
  } from 'lodash'
  export default {
    components: {},
    props: {
      elevatorId: {
        type: String,
        default: '',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        visible: false,
        loading: true,
        updateVideoSearch: {
          time: '',
          head: '',
          liftSpeed: ''
        },
        updateVideoList: [],
        newUpdateVideoLis: [],
        initArr: [],
        havePersonDay: '',
        upload: '0%', // 上传进度
        i: 0,
      }
    },
    mounted() {
      this.updateVideoSearch.time = new Date()
    },
    methods: {
      //全选
      selectAll(selection) {
        this.initArr = selection
      },

      handleSelectionChange(val, row) {
        if (this.initArr.find((item) => item.id === row.id)) {
          this.initArr = this.initArr.filter((item) => item.id !== row.id)
        } else {
          this.initArr.push(row)
        }
      },

      // 获取视频列表
      searchElevator() {
        this.newUpdateVideoLis = []
        this.loading = true
        if (!this.updateVideoSearch.time) {
          this.$message.error('请选择日期')
          this.loading = false
          return
        }
        // 转换格式
        let y = this.updateVideoSearch.time.getFullYear()
        let m = this.updateVideoSearch.time.getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = this.updateVideoSearch.time.getDate()
        d = d < 10 ? '0' + d : d
        const day = `${y}${m}${d}`
        this.havePersonDay = `${y}-${m}-${d}`

        if (this.socketApi.isheart) {
          const agentData = {
            act: 'server_push_get_local_video_list',
            token: JSON.parse(sessionStorage.getItem('adminInfo')).token.token,
            startTime: `${day}000000`,
            endTime: `${day}235959`,
            deviceId: this.deviceCode,
          }
          this.socketApi.sendSock(agentData, (e) => {
            if (e.cmd === 'server_push_get_local_video_list' && e.status) {
              this.updateVideoList = e.data
              // this.updateVideoList.forEach((item) => {
              //   item.upload = '未上传'
              // })
              this.getHavePerson(this.havePersonDay)
            }
          })
        }
      },
      reast() {
        this.updateVideoSearch.head = ''
        this.updateVideoSearch.liftSpeed = ''
        this.updateVideoSearch.time = new Date()
        // 转换格式
        this.getHavePerson(this.havePersonDay)
      },

      getHavePerson(time) {
        this.$http.get(
          `/api/ele/web/videoData/getData?deviceCode=${this.deviceCode}&startTime=${time} 00:00:00&endTime=${time} 23:59:59&head=${this.updateVideoSearch.head}&liftSpeed=${this.updateVideoSearch.liftSpeed}`
        ).then((res) => {
          let videoDataArr = Object.keys(res.data.data)
          videoDataArr.forEach(item => {
            this.updateVideoList.map(ListItem => {
              let keyArr = ListItem.file_name.split('/')[1].split('-')
              if (item == `${keyArr[0]}-${keyArr[1]}`) {
                ListItem.head = res.data.data[item].head
                ListItem.liftSpeed = res.data.data[item].liftSpeed
              }
            })
          })
            if ((this.updateVideoSearch.head !== '' || this.updateVideoSearch.liftSpeed !== '') ) {
                this.newUpdateVideoLis = this.updateVideoList
                if (this.updateVideoSearch.head !== '') {
                  this.newUpdateVideoLis = this.newUpdateVideoLis.filter(item => item.head !== '' && this.updateVideoSearch.head == item.head)
                }
                if (this.updateVideoSearch.liftSpeed !== '') {
                  this.newUpdateVideoLis = this.newUpdateVideoLis.filter(item => item.liftSpeed !== '' && this.updateVideoSearch.liftSpeed == item.liftSpeed)
                }

              } else{
                this.newUpdateVideoLis = this.updateVideoList
              }
          this.loading = false
        })
      },

      // 上传
      update(url, row) {
        this.$set(
          this.newUpdateVideoLis.find((item) => item.file_md5 === row.file_md5),
          'upload',
          '0%'
        )
        const urlArr = [url]
        const data = {
          deviceId: this.deviceCode,
          fileList: urlArr,
          type: 1,
          md5: row.file_md5,
          talkBackId: row.file_md5,
        }
        this.$http.post('/api/ele/web/eleLog/pushFile', data).then((res) => {
          if (res.data.success) {
            this.$message.success(res.data.msg)
            // 循环调用获取进度
            // this.timer = setInterval(() => {
            //   // this.get_progress(row)
            // }, 5 * 1000)
          } else {
            this.$set(
              this.equipmentList.find((item) => item.Path === row.Path),
              'upload',
              ''
            )
            this.$message.error(res.data.msg)
          }
        })
      },

      // 批量上传
      batchUpload() {},

      // 获取对应文件的上传进度
      get_progress(row) {
        this.i++
        this.$http
          .get(`/api/group1/uploadGetProgress?X-Progress-ID=${row.file_md5}`)
          .then((res) => {
            if (res.data) {
              const data = res.data

              if (data.status === 'uploading') {
                this.upload = `${data.message}%`
                this.$set(
                  this.newUpdateVideoLis.find(
                    (item) => item.file_md5 === row.file_md5
                  ),
                  'upload',
                  this.upload
                )
              }
              // 上传开始
              if (data.status === 'starting') {
                this.upload = '50%'

                this.$set(
                  this.newUpdateVideoLis.find(
                    (item) => item.file_md5 === row.file_md5
                  ),
                  'upload',
                  this.upload
                )
              }
              // 上传开始
              // if (data.status === '') {
              //   const num = this.i * 5 < 100 ? this.i * 5 : 99
              //   this.upload = `${num}%`

              //   this.$set(
              //     this.updateVideoList.find(
              //       (item) => item.file_md5 === row.file_md5
              //     ),
              //     'upload',
              //     this.upload
              //   )
              // }
              // 上传完成
              if (data.status === 'ok') {
                this.i = 0
                this.upload = '100%'
                this.$set(
                  this.newUpdateVideoLis.find(
                    (item) => item.file_md5 === row.file_md5
                  ),
                  'upload',
                  this.upload
                )
                clearInterval(this.timer)
              }
              // 删除定时器对应md5的上传文件
              if (this.upload === '100%') {
                clearInterval(this.timer)
              }
            }
          })
      },

      updateVideoDialogClose() {
        this.$refs.updateVideoList.setCurrentRow()
        this.$refs.updateVideoList.clearSelection()
      },
    },
  }
</script>
<style lang="scss" scoped>
  /deep/.el-loading-mask {
    background-color: #7474744d !important;
  }
</style>
