const getStatusName = (status, list, key = 'label', value = 'value') => {
  let result = list.filter(item => item[value] == status)
  return result.length > 0 ? result[0][key] : status
}
const getTimeSE = (val) => {
  const time = {
    start: 0,
    end: 0
  }
  if (val == 3) {
    let arr = [6, 0, 1, 2, 3, 4, 5]
    time.start = (new Date(new Date().setHours(0, 0, 0) - arr[new Date().getDay()] * 24 * 60 * 60 * 1000)).getTime()
    time.end = time.start + 7 * 24 * 60 * 60 * 1000 - 1000
  } else if (val == 1) {
    time.start = (new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0))).getTime()
    time.end = (new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 59)))
      .getTime()
  } else if (val == 2) {
    time.start = (new Date(new Date().getFullYear(), 0, 1)).getTime()
    time.end = (new Date(new Date(new Date().getFullYear() + 1, 0, 0).setHours(23, 59, 59, 59))).getTime()
  } else if (val == 4) {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const lastMonthFirstDay = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() - 1, 1);
    const lastMonthLastDay = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), 0);
    time.start = lastMonthFirstDay.getTime()
    time.end = new Date(lastMonthLastDay.setHours(23, 59, 59, 59)).getTime()
  }
  return time
}
const getMonthSE = (fullYear = new Date().getFullYear(), month = new Date().getMonth()) => {
  return {
    start: (new Date(new Date(fullYear, month, 1).setHours(0, 0, 0))).getTime(),
    end: (new Date(new Date(fullYear, month + 1, 0).setHours(23, 59, 59, 59))).getTime()
  }
}
/**
 * 日期格式化
 * @param {date} oldDate new Date能接受的格式
 * @param {string} format
 * @returns string
 */
const formatDate = (oldDate, format = 'yyyy-MM-dd HH:mm:ss') => {
  if (!oldDate) return '-'
  const date = new Date(oldDate)
  const config = {
    yyyy: date.getFullYear(),
    M: date.getMonth() + 1,
    MM: formatNum(date.getMonth() + 1),
    W: date.getDay(),
    WW: formatNum(date.getDay()),
    d: date.getDate(),
    dd: formatNum(date.getDate()),
    H: date.getHours(),
    HH: formatNum(date.getHours()),
    h: date.getHours() > 12 ? (date.getHours() - 12) : date.getHours(),
    hh: formatNum(date.getHours()) > 12 ? (formatNum(date.getHours()) - 12) : formatNum(date.getHours()),
    m: date.getMinutes(),
    mm: formatNum(date.getMinutes()),
    s: date.getSeconds(),
    ss: formatNum(date.getSeconds()),
    A: date.getHours() < 12 ? 'AM' : 'PM',
    a: date.getHours() < 12 ? 'am' : 'pm'
  }
  const formatConfigs = format.match(/[a-zA-Z]+/g)
  formatConfigs.forEach(item => {
    format = format.replace(item, config[item])
  })
  return format
}

function formatNum(num) {
  return num < 10 ? `0${num}` : num
}
export {
  getStatusName,
  getTimeSE,
  getMonthSE,
  formatDate
}
