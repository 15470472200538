<template>
  <div
    :class="className"
    :style="{width:width, height:height,margin:'0 auto'}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '650px',
    },
    trendColor: {
      type: Array,
      default: () => [],
    },
    sosLineData: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions(data) {
      const dateList = [ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]
      const series = data.map((item, index) => {
        let obj = {
            name: '测试电梯',
            type: 'line',
            data: [ "7.24", "6.88", "7.44", "7.78", "8.74", "8.19", "8.93", "7.03", "4.47", "7.14", "9.25", "9.96", "10.42", "7.87", "9.26", "9.09", "9.45", "9.25", "8.55", "9.28", "8.15", "7.93", "8.40", "9.47", "9.88", "9.50", "9.74", "10.29", "9.19", "8.65", "10.01"],
            itemStyle: {
              normal: {
                color: '#ff0000',
              },
            },
            lineStyle: {
              smooth: true,
            },
          }
          return obj
      })

      const options = {
        tooltip: {
          trigger: 'axis',
          // 在这里设置
          formatter: (params) => {
            let value = ``
            let time = ''
            params.map((item) => {
              time = `${item.name}号`
              value += `${item.seriesName}：${item.value} 小时<br>`
            })
            return `${time}<br>${value}`
          },
        },
        textStyle: {
          color: '#fff', // 所有文字的颜色
        },
        grid: {
          left: '10%',
          right: '4%',
          top: '12%',
          bottom: '20%',
          containLabel: false,
          borderColor: '#fff',
        },
        xAxis: {
          type: 'category',
          show: true,
          data: dateList,
          boundaryGap: false,
          splitLine: { show: false }, // 去除网格线,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed', // 坐标轴颜色
            },
          },
          axisLabel: {
            interval: 6,
            rotate: 0,
            textStyle: {
              fontSize: 14,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '',
          show: true,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed',
            },
          },
          scale: true, //自适应
          axisTick: {
            show: false,
          },
        },
        series: [...series]
      }
      this.chart.setOption(options)
    },
  },
}
</script>
