<template>
  <div>
    <div class="parentRec">
      <div class="loginRec">
        <div class="rRec">
          <div class="inpu">
            <div class="rRec01">
              登 录
            </div>
            <div class="rRec02">
              <div class="inpdiv">
                <div class="rinp">
                  <i class="el-icon-user"></i>
                  <el-input @input="isHint()" placeholder="请输入内容" v-model="userName" clearable>
                  </el-input>
                </div>
                <div v-if="isShow==true" :class='userName.length>=2?"tsno":"ts"'>
                  {{hint}}
                </div>
              </div>
              <div class="inpdiv">
                <div class="rinp">
                  <i class="el-icon-key"></i>
                  <el-input @input="isHint01()" placeholder="请输入密码" v-model="password" show-password>
                  </el-input>
                </div>
                <div v-if="isShow01==true" :class='password.length>=4?"tsno":"ts"'>
                  {{passHint}}
                </div>
              </div>
              <div class="inpdiv">
								<div class="rinp codeImg">
									<i class="el-icon-position"></i>
                  <template>
									  <el-input v-model="imgCode" placeholder="请输入验证码" @input="showAuth()" clearable style="width: 55%;margin-left: 10px"/>
                    <el-image
                      fit="contain" :src="codeUrl" @click="getCode"
                      style="width:160px; height:40px; margin-left: 20px" :key="codeImgNum" />
                  </template>
								</div>
                <div :class='imgCode.length>=4?"tsno":"ts"'>
                  {{codeHint}}
                </div>
							</div>
              <div class="inpdiv">
                <div class="rinp rinpSpace">
                  <el-checkbox v-model="isRemember" style="color:#Fff">记住密码</el-checkbox>
                  <el-button @click="forget()" style="font-size: 12x; float:right; color:#Fff"
                    type="text">忘记密码</el-button>
                </div>
              </div>
            </div>
            <div class="rRec03">
              <el-button v-show="!isLogin" @click="goHome()" style="font-size: 18px;" type="primary" plain>登 录
              </el-button>
              <el-button v-show="isLogin" style="font-size: 18px;" type="primary" plain>登 录 中...
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <ForgetPwd ref="ForgetPwd" />
      <!-- <AgainLogin ref="AgainLogin" v-if="codeShow" /> -->
    </div>
    <div id="particles-js" style="display: flex;align-items: center;justify-content: center;    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;"></div>
	</div>
</template>

<script>
	import particlesJS from '../../../public/static/particles.js'

  import base from '../../Base64.js'
  import Cookie from 'js-cookie'
  // import AgainLogin from './againLogin'
  import ForgetPwd from './forgetPwd'
  import versionTood from '@/libs/versionUpdate'
  export default {
    components: {
      ForgetPwd,
      // AgainLogin
    },
    data() {
      return {
        isLogin: false,
        isShow: false,
        isShow01: false,
        isShowCode: false,
        isRemember: false,
        userName: '',
        password: '',
        imgCode: '',
        hint: '请输入字母、数字、下划线,长度不能少于2',
        passHint: '管理员密码长度应大于6',
        codeHint: '请先填写完整的验证码',
        codeShow: false,

        codeUrl: '',
        codeImgNum: 0
      }
    },
    methods: {
      // 登录事件
      goHome() {
        if (this.userName != '' && this.password != '' && this.imgCode != '') {
          this.isLogin = true
          let data = {
            userName: this.userName,
            password: this.password,
            imgCode: this.imgCode
          }
          this.axios.post('/api/system/web/user/doLogin', data).then((res) => {
            if (res.data.success) {
              localStorage.setItem("buttonShow",true)
              let agentData = {
                act: 'ma_login',
                token: res.data.data.token.token,
              }
              this.socketApi.initWebSocket()
              this.socketApi.sendSock(agentData, (e) => {
                if (e.cmd == 'ma_login' && e.status) {
                  //保存密码并加密
                  if (this.isRemember) {
                    localStorage.setItem('userName', base.encode(this.userName))
                    localStorage.setItem('password', base.encode(this.password))
                  } else {
                    const showNotice = localStorage.getItem('isShowNotice')
                    const vueVersion = localStorage.getItem('vueVersion')
                    localStorage.clear()
                    localStorage.setItem('isShowNotice', showNotice)
                    localStorage.setItem('vueVersion', vueVersion)
                  }
                  sessionStorage.setItem(
                    'adminInfo',
                    JSON.stringify(res.data.data)
                  )
                  sessionStorage.setItem('AdminToken', res.data.data.token.token)
                  sessionStorage.setItem('me_id', e.data)
                  if (!e.data) {
                    this.socketApi.sendSock(agentData, (e) => {
                      sessionStorage.setItem('me_id', e.data)
                    })
                  }
                  sessionStorage.setItem('sosConfig', JSON.stringify(res.data.data
                    .sosConfig))


                  setTimeout(() => {
                    this.$http.post('/api/bike/web/config/getSosConfig', {}).then((bikeRes) => {
                      if (bikeRes.data.code == 200) {
                        const bikeSosConfig = res.data.data.userName == 'admin' ? bikeRes.data.data : bikeRes.data.data.filter(item => item.sosType !== 35)
                        sessionStorage.setItem("bikeSosConfig", JSON.stringify(bikeSosConfig));

                      }
                    })
                  },100)
                  if (Number(res.data.data.roleId) != 8&&Number(res.data.data.roleId) != 9) {
                    let routeUrl = ''
                    if (res.data.data.resource[0].children) {
                      routeUrl = res.data.data.resource[0].children[0].routePath
                    } else{
                      routeUrl = res.data.data.resource[0].routePath
                    }
                    this.$router.push(routeUrl)
                    // /dashboard
                  }else{
                    if(Number(res.data.data.roleId) == 8){
                      this.$router.push('/cwgl/czjl')
                    }else{
                      this.$router.push('/cwgl/txsp')
                    }
                  }
                  this.$message.success('登录成功')
                } else {
                  this.isLogin = false
                  this.$message.error(e.msg)
                }
              })
              // 安全帽
              this.socketCapsApi.initCapsSocket()
              this.socketCapsApi.sendCapsSock(agentData, (e) => {
                if (e.cmd == 'ma_login' && e.status) {
                } else {
                  this.isLogin = false
                  this.$message.error(e.msg)
                }
              })
            } else {
              this.isLogin = false
              if (res.data.code != 1001) {
                if(res.data.code == 400){
                  this.getCode()
                }
                this.$message.error(res.data.msg)
              } else {
                // this.codeShow = true
                // this.$nextTick(() => {
                //   this.$refs.AgainLogin.init(data, res.data.data.phone, () => {})
                // })
              }
            }
          })
        } else {
          this.$message.error('请先填写账号或密码或验证码!')
        }
        // isRemember
      },
      forget() {
        console.log('忘记密码')
        this.$nextTick(() => {
          this.$refs.ForgetPwd.init(() => {})
        })
      },
      // 账户验证
      isHint() {
        this.isShow = true
        if (this.userName == '') {
          this.hint = '输入内容不能为空'
        } else {
          this.hint = '请输入字母、数字、下划线,长度不能少于2'
        }
      },
      // 密码验证
      isHint01() {
        this.isShow01 = true
        if (this.password == '') {
          this.passHint = '输入内容不能为空'
        } else {
          this.passHint = '管理员密码长度应大于6'
        }
      },
      // 验证码验证
      showAuth() {
        this.isShowCode = true
        if (this.imgCode == '' || this.imgCode.length < 4) {
          this.codeHint = '请先填写完整的验证码'
          // this.$message.error('请先填写验证码!')
        }
      },
      // 回车登录
      keyDown(e) {
        //如果是回车则执行登录方法
        if (e.keyCode == 13) {
          //需要执行的登录方法
          this.goHome()
        }
      },


      getCode(){
         this.axios.post('/api/system/web/login/getImgCode').then((res) => {
           this.codeImgNum++
            this.codeUrl = res.request.responseURL
         })
      }
    },
    mounted() {
      this.$nextTick(() => {
        openLz();
        this.getCode()
      });
      window.addEventListener('keydown', this.keyDown)
    },
    destroyed() {
      window.removeEventListener('keydown', this.keyDown, false)
    },
    created() {
      versionTood.isNewVersion();
      // 判断上次登录操作是否记住密码
      if (localStorage.getItem('userName') != null) {
        this.isRemember = true
        this.userName = base.decode(localStorage.getItem('userName'))
        this.password = base.decode(localStorage.getItem('password'))
      }
      if (!this.$store.state.isZx) {
        document.title =
          window.location.hostname == "zxtlw.zyzntech.com" ?
          '中信数字化电梯物联网监管系统' : '数字化电梯物联网安全监管系统'
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = window.location.hostname == "zxtlw.zyzntech.com" ? require('@/assets/zhongxin.png') :
          'https://zxtlw.zyzntech.com/public/images/202306211058112481.png';
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    },
  }
</script>
<style lang="scss" scoped>
	.yzm {
		width: 20%;
		height: 100%;
		font-size: 12px;
		color: wheat;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ts {
		font-size: 12px;
		color: red;
		width: 85%;
		height: 40%;
		// border-top: 2px solid #ff0000;
		text-align: left;
		display: block;
		margin-left: 10%;
		margin-top: 1%;
	}

	.tsno {
		width: 85%;
		height: 40%;
		display: none;
	}

	.inpdiv {
		width: 100%;
		height: 20%;
		display: flex;
		flex-direction: column;
	}

	.rinps {
		width: 100%;
		height: 70%;
		color: white;
		background-color: #1282a5;
		/* background: url('../../assets/loginBG.png'); */
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.rinp {
		width: 100%;
		height: 70%;
		color: white;
		// background-color: #1282a5;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.rinpSpace {
		justify-content: space-between;
	}

	.rRec01 {
		width: 100%;
		height: 15%;
		font-size: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.rRec02 {
		width: 100%;
		height: 60%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
	}

	.rRec03 {
		width: 100%;
		height: 20%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.inpu {
		width: 76%;
		height: 80%;
	}

	.rRec {
		width: 50%;
		height: 100%;
		/* background-color: #1282a5; */
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		background-color: rgba(255, 255, 255, 0.1);
		background-size: 100% 100%;
		backdrop-filter: blur(10px);
		border-radius: 15px;

		/deep/.el-input__inner {
			background-color: #fff !important;
			color: #000 !important;
		}
	}

	.loginRec {
		width: 1000px;
		height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
	}

	.parentRec {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url(../../assets/loginImg.png);
		background-size: 100% 100%;
	}
</style>

<style scoped>
	.rinps>>>.el-input__inner {
		border: none !important;
		outline: none !important;
		margin-left: 20px;
	}

	.rinp>>>.el-input__inner {
		width: 94% !important;
		height: 38px !important;
		border: none !important;
		outline: none !important;
		margin-left: 20px;
	}

	.rinp>>>.el-input .el-button {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
	}

	.rinp>>>.el-input__suffix {
		right: 15px;
	}

	.rinp>>>.el-input__icon {
		line-height: 32px;
	}

  .rRec03>>>.el-button {
    width: 100% !important;
    height: 60% !important;
  }
  .codeImg>>>.el-input__inner {
    margin-left: 10px;
  }
</style>
