import {
	Message
} from 'element-ui';
var isheart = false;
var websock = null;
var sosPrompt = [];
var onlineDevice = [];
var global_callback = {}

let cuIP = window.location.host
let curIP = window.location.hostname

let continueSend = true

function initWebSocket() { //初始化weosocket
	//ws地址
	var wsuri
	if (curIP == 'localhost' || curIP.indexOf('192.168.') !== -1) {
		// wsuri = "ws://192.168.108.127:9401/ws"; //测试服，第二版
		// wsuri = "wss://tzb.runde.pro/wss"; //正式测试服，第二版

		// wsuri = "wss://tlwtest.zyzntech.com/wss"; //开发测试服，第二版
		// wsuri = "wss://tlw-test.runde.pro/wss"; //测试测试服，第二版
		// wsuri = "wss://tt.zhongzexin.pro/wss"; //测试测试服，第二版
		wsuri = "wss://zxtlw.zyzntech.com/wss"; //中信测试服，第二版
	} else {
		wsuri = "wss://" + cuIP + "/wss"; //freeswitch通话
	}
	// console.log(websock,'websock111111');
	// if (websock) {
	// 	websock.close(1000)
	// 	console.log('websock.close');
	// }
	websock = new WebSocket(wsuri);
	websock.onmessage = function (e) {
		websocketonmessage(e);
	}
	websock.onclose = function (e) {
		console.log("WebSocket死掉了");
		websocketclose(e);
	}
	websock.onopen = function () {
		websocketOpen();
	}
	//连接发生错误的回调方法
	websock.onerror = function () {
		// stopHeartBeat()
		// reconnect()
		console.log("WebSocket连接发生错误");
	}
}

function reconnect() {
	setTimeout(function () { //没连接上会一直重连，设置延迟避免请求过多
		initWebSocket()
	}, 4000);
}

// 心跳
function heartBeat() {
	console.log('我在发心跳1', isheart)
	var agentData = {
		"act": "1"
	};
	if (!isheart) {
		return;
	}
	setTimeout(function () {
		sendSock(agentData)
	}, 30 * 1000);
}

// 清空push_sos
function clearSos(e) {
	sosPrompt = []
}

//报警数据接收
function websocketonmessage(e) {
	let data = JSON.parse(e.data)
	if (data.cmd == 'push_sos') {
		//sos报警
		sosPrompt.push(data)
	} else if (data.cmd == '1') {
		if (isheart) {
			heartBeat();
		}
	} else if (data.hb == 'ma_set_sip_info') {
		global_callback[data.hb](data)
	} else if (data.cmd != undefined) {
		if (data.cmd != 'error') {
			global_callback[data.cmd](data)
		} else {
			if (data.msg == '用户离线请重新登录！') {
				continueSend = false
			}
			Message.error(data.msg)
		}
	} else if (data.hb == 'ca_start_rtsp') {
		global_callback[data.hb](data)
	}

}
//数据发送
function sendSock(agentData, callback) {
	if (navigator.onLine && isheart || agentData.act == "ma_login") {
		if (agentData.act != '1') {
			global_callback[agentData.act] = callback
			if (agentData.act == 'ma_set_sip_info') {
				global_callback['ma_set_sip_info'] = callback
			}
		}
		if (websock.readyState === websock.OPEN) {
			//若是ws开启状态
			if (continueSend) {
				//若是用户未离线
				websock.send(JSON.stringify(agentData));
			}
		} else {
			// 若未开启 ，则等待1s后重新调用
			setTimeout(function () {
				websock.send(JSON.stringify(agentData));
			}, 2 * 1000);
		}
	} else {
		console.log('网络或长连接之类的出现问题了', agentData)
		// stopHeartBeat()
	}
}

function websocketclose(e) {
	stopHeartBeat()
	console.log("connection closed (" + e.code + ")");
}

function websocketOpen(e) {
	isheart = true
	heartBeat();
	console.log("连接成功");
}

//页面实际调用的方法
// 结束心跳
function stopHeartBeat() {
	isheart = false;
	// sessionStorage.removeItem('me_id')
	websock.close()
	console.log('socket结束心跳', websock)
}

export {
	isheart,
	websock,
	clearSos,
	sendSock,
	onlineDevice,
	sosPrompt,
	// 结束心跳
	stopHeartBeat,
	initWebSocket,
}
