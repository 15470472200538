<template>
  <div>
    <div class="parentRec">
      <div class="loginRec">
        <div class="rRec">
          <div class="inpu">
            <div class="rRec01">正在进去平台，请稍等....</div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="particles-js"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      "
    ></div>
  </div>
</template>

<script>
import particlesJS from "../../../public/static/particles.js";

import base from "../../Base64.js";
import Cookie from "js-cookie";
export default {
  components: {},
  data() {
    return {
      isLogin: false,
      isShow: false,
      isShow01: false,
      isRemember: false,
      userName: "",
      password: "",
      auth: "666666",
      hint: "请输入字母、数字、下划线,长度不能少于2",
      passHint: "管理员密码长度应大于6",
      codeShow: false,
      AppKey: '',
      AppSecret: ''
    };
  },
  methods: {
    // 登录事件
    goHome() {
      this.AppKey = sessionStorage.getItem("AppKey");
      this.AppSecret = sessionStorage.getItem("AppSecret");
      this.AppType = sessionStorage.getItem("AppType");
      this.axios
        .get(`/api/system/web/user/freeLogin?AppKey=${this.AppKey}&AppSecret=${this.AppSecret}`)
        .then((res) => {
          if (res.data.success) {
            sessionStorage.setItem('AdminToken', res.data.data.token.token)
            sessionStorage.setItem("adminInfo", JSON.stringify(res.data.data));
            sessionStorage.setItem("sosConfig", JSON.stringify(res.data.data.sosConfig));
            sessionStorage.setItem("bikeSosConfig", JSON.stringify(res.data.data.bikeSosConfig));
            let agentData = {
              act: "ma_login",
              token: res.data.data.token.token,
            };
            this.socketApi.initWebSocket();
            this.socketApi.sendSock(agentData, (e) => {
              if (e.cmd == "ma_login") {
                sessionStorage.setItem("me_id", e.data);
              }
            });

            setTimeout(() => {
              if (this.AppType == 1) {
                if (res.data.data.bigScreenType == 1) {
                  this.$router.push("/newBigmianban");
                } else if (res.data.data.bigScreenType == 2) {
                  this.$router.push("/operate");
                } else {
                  this.$router.push("/maintenanceBigmianban");
                }
              } else {
                if (Number(res.data.data.roleId) != 8&&Number(res.data.data.roleId) != 9) {
                  let routeUrl = ''
                  if (res.data.data.resource[0].children) {
                    routeUrl = res.data.data.resource[0].children[0].routePath
                  } else{
                    routeUrl = res.data.data.resource[0].routePath
                  }
                  this.$router.push(routeUrl)
                  // this.$router.push('/dashboard')
                  // /dashboard
                } else {
                  if(Number(res.data.data.roleId) == 8) {
                    this.$router.push('/cwgl/czjl')
                  } else {
                    this.$router.push('/cwgl/txsp')
                  }
                }
              }
            }, 500);
          } else {
            this.$message.error(res.data.msg);
            this.$router.push("/login");
          }
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      openLz();
    });
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  created() {
    if (this.$store.state.isZx && sessionStorage.getItem("AppType") == 1) {
      document.title = "锦地梯控物联网监管系统";
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = require("@/assets/zhongxin.png");
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    this.goHome();
  },
};
</script>
<style lang="scss" scoped>
.yzm {
  width: 20%;
  height: 100%;
  font-size: 12px;
  color: wheat;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ts {
  font-size: 12px;
  color: red;
  width: 85%;
  height: 40%;
  // border-top: 2px solid #ff0000;
  text-align: left;
  display: block;
  margin-left: 10%;
  margin-top: 1%;
}

.tsno {
  width: 85%;
  height: 40%;
  display: none;
}

.inpdiv {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
}

.rinps {
  width: 100%;
  height: 70%;
  color: white;
  background-color: #1282a5;
  /* background: url('../../assets/loginBG.png'); */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rinp {
  width: 100%;
  height: 70%;
  color: white;
  // background-color: #1282a5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rinpSpace {
  justify-content: space-between;
}

.rRec01 {
  width: 100%;
  height: 15%;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rRec02 {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.rRec03 {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.inpu {
  width: 76%;
  height: 80%;
  display: flex;
  align-items: center;
}

.rRec {
  width: 50%;
  height: 100%;
  /* background-color: #1282a5; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  background-size: 100% 100%;
  backdrop-filter: blur(10px);
  border-radius: 15px;

  /deep/.el-input__inner {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.loginRec {
  width: 1000px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.parentRec {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/loginImg.png);
  background-size: 100% 100%;
}
</style>

<style scoped>
.rinps >>> .el-input__inner {
  border: none !important;
  outline: none !important;
  margin-left: 20px;
}

.rinp >>> .el-input__inner {
  width: 94% !important;
  height: 38px !important;
  border: none !important;
  outline: none !important;
  margin-left: 20px;
}

.rinp >>> .el-input .el-button {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}

.rinp >>> .el-input__suffix {
  right: 15px;
}

.rinp >>> .el-input__icon {
  line-height: 32px;
}

.rRec03 >>> .el-button {
  width: 100% !important;
  height: 60% !important;
}
</style>
