<template>
  <div class="admin-project-myproject-container">
    <el-table :data="mainList" v-loading="tableLoading" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight-100">
      <el-table-column type="index" label="序号" min-width="55px" />
      <el-table-column label="电梯名称" prop="eleName" min-width="150px" align="center"/>
      <el-table-column label="电梯注册代码" prop="eleCode" min-width="150px" align="center"/>
      <el-table-column label="故障描述" prop="failureDesc" min-width="320px" align="center"/>
      <el-table-column label="故障来源" prop="sourceName" min-width="100px" align="center"/>
      <el-table-column label="处理状态" prop="statusName" min-width="100px" align="center"/>
      <el-table-column label="故障报告时间" prop="failureTime" min-width="160px" align="center"/>
      <el-table-column label="所属小区" prop="plotName" min-width="100px" align="center"/>
      <el-table-column label="物业单位" prop="propertyUnitName" min-width="150px" align="center"/>
      <el-table-column label="维保单位" prop="maintainUnitName" min-width="150px" align="center"/>
      <el-table-column label="操作" min-width="140" align="center" fixed="right">
        <template #default="scope">
          <el-button type="primary" @click="check(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="Total" :page.sync="formInline.current" :limit.sync="formInline.size"
      @pagination="dictionarPage" />
    <el-dialog v-if='dioDetails' :close-on-click-modal='false' append-to-body :title="tit" :visible.sync="dioDetails"
      width="80%" top='10vh' >
      <div style="height: 65vh;overflow-y: scroll;" class="scrollElement">
        <dioDetails :dioData="dioDetailsItem" :flowList="flowList"></dioDetails>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="closeDetails">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import dioDetails from '../../../gzsb/gzsb/components/dioDetails.vue'
  export default {
    components: {
      Pagination,
      dioDetails
    },
    props: {
      elevatorId: {
        type: String,
        default: '',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        mainList: [],
        formInline: {
          current: 1,
          size: 10,
        },
        Total: 0,
        dioDetails: false,
        tit: '',
        process: false,
        // flowList: [],
        dioDetailsItem: null,
        flowList: null,
        tableLoading: true
      }
    },
    mounted() {
      this.getMainList()
    },
    created() {
      this.dectionary(['FAILURE_STATUS', 'FAILURE_SOURCE_TYPE'])
    },
    methods: {
      dectionary(code) {
        this.$http.post(`api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            this.typeDate = res.data.data
          })
      },
      getTName(typeDate, obj) {
        let tn = []
        typeDate.forEach(qs => {
          if (qs.value == obj) {
            tn.push(qs.label)
            return
          }
        })
        return tn.join(',')
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.getMainList()
      },
      getMainList() { //电梯维修数据
        const data = {
          current: this.formInline.current,
          size: this.formInline.size,
          eleId: this.elevatorId
        }
        this.tableLoading = true
        this.$http.post('/api/ele/web/failureTask/getList', data)
          .then((res) => {
            res.data.data.records.forEach((qs) => {
              qs.statusName = this.getTName(this.typeDate.FAILURE_STATUS, qs.status)
              qs.sourceName = this.getTName(this.typeDate.FAILURE_SOURCE_TYPE, qs.sourceType)
            })
            this.mainList = res.data.data.records ?? []
            this.Total = res.data.data.total ?? 0
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
            this.tableLoading = false
          })

      },
      check(item) {
        this.tit = '故障申报详情'
        this.dioDetailsItem = item
        this.getDetail(item)
        this.dioDetails = true
        this.process = false
      },
      getDetail(item) {
        let data = {
          taskId: item.id,
        }
        let url = '/api/ele/web/failureTask/detail'
        this.$http.post(url, data).then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              let num = res.data.data[res.data.data.length - 1].status
            }
            this.flowList = res.data.data.filter((item) => item.status < 10)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      closeDetails() {
        this.dioDetails = false
      },
    }
  }
</script>

<style lang="scss" scoped>
  .el-dialog {
    height: 75vh !important;
  }
</style>
