module.exports = {
	language: {
		name: 'English'
	},
	homes: {
		home: '首页',
		operate: '运营管理',
		commodity: '商品管理',
		extension: '推广管理',
		system: '系统管理',

		order: '订单管理',
		comment: '评论管理',
		freight: '运费模板管理',
		
		goodsredact: '商品编辑',
		management: '商品列表',
		classify: '商品类目',

		shop: '商铺广告',
		recommend: '推荐管理',
		discounts: '优惠卷管理',
		shopmessage: '商铺信息',

		member: '会员管理',
		Administrator: '管理员',
		role: '角色管理',
		
		login: '登录'
	}
}
