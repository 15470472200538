                                                 <template>
  <el-dialog
    title="电池更换提醒"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="900px"
    @close="selectunitConfirm"
  >
    <div class="admin-project-myproject-container batterybox" >
      <h3>该电梯的AI智能终端的电池健康度小于等于40%，请及时更换电池！</h3>
      <el-checkbox v-model="checked" style="margin: 4vh">该电梯今日不再提示</el-checkbox>
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        type="primary"
        @click="selectunitConfirm"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {
  },
  props: ['code'],
  data () {
    return {
      visible: true,
      loading: true,
      checked: false,
      showBatteryList: []
    }
  },
  mounted () {
  },
  methods: {

    selectunitConfirm () {
        this.visible = false
        if (this.checked) {
          this.showBatteryList = sessionStorage.getItem('isShowBatteryList') ? JSON.parse(sessionStorage.getItem('isShowBatteryList')) : []
          // this.showBatteryList.push(this.code)
          if (this.showBatteryList.length > 0) {
            if (this.showBatteryList.find(item => item.code == this.code)) {
              this.showBatteryList.find(item => item.code == this.code).checked = this.checked
            } else {
              this.showBatteryList.push({
                code: this.code,
                checked: this.checked
              })
            }
          } else {
            this.showBatteryList.push({
              code: this.code,
              checked: this.checked
            })
          }
          sessionStorage.setItem('isShowBatteryList', JSON.stringify(this.showBatteryList))
        }
        this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.batterybox{
  height: 30vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  h3{
    margin: 8vh 0;
  }
}
</style>
