<template>
  <div
    class="map-container custom-map"
    style="position: relative; height: 29.5vh; padding: 0 10px 15px"
  >
    <!-- echarts中国地图 -->
    <div class="echarts-container">
      <div id="myChart" ref="myChart"></div>
    </div>
  </div>
</template>
<script>
let myChart = null;
let dataList = [
    {
        name: '内蒙古自治区',
        value: 54
    },
    {
        name: '新疆',
        value: 12
    }
];

import * as echarts from "echarts";
export default {
  props: {
    sosMapData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      //echarts相关数据
      //地图json数据
      echartsJson: {
        features: [],
        type: "FeatureCollection",
      },
      //地图标注设备数据
      convertData: [],
      //当前地图区域信息对象
      regionData: {},
      initRegionData: {},

      //高德地图相关
      //地图开关
      showMapGD: false,
      //地图中心点
      mapCenterGD: null,
    };
  },
  computed: {},
  watch: {
    showMapGD(value) {
      this.$emit("changeShowMapGD", value);
    },
  },
  methods: {
    toManageAreaCode(arr) {
      arr = arr ? JSON.parse(arr) : arr;
      let manageAreaCode = !arr || !arr[0] ? [] : arr[0];
      return ["", ...manageAreaCode];
    },
    //绘制echarts地图
    // async echartMapInit(regionData={name:'中国',adcode:0},resetMap=true,historyData){
    async echartMapInit(
      regionData = this.initRegionData,
      resetMap = true,
      historyData
    ) {

      // myChart.showLoading({ text: '正在加载数据' });
      if (historyData) {
        this.regionData = historyData.regionData;
        this.echartsJson = historyData.echartsJson;
        this.convertData = historyData.convertData;
      } else {
        this.regionData = regionData;
        console.log(this.regionData.adcode,'66')
        const res = await this.$http.get(
          `/api/ele/web/bigScreen/map/elevator?adcode=${this.regionData.adcode}`
        );
        if (res.status == 200 && res.data.code == 200) {
          this.echartsJson = res.data.data.aliData;
        }
      }
      //调用我们通过json对象注册的地图
      echarts.registerMap("China", this.echartsJson);
      //设置地图选项
      this.setMapOption();
    },
    //配置echarts选项
    setMapOption() {
      console.log(this.sosMapData, 'sosMapData');
      myChart = myChart
        ? myChart
        : echarts.init(document.getElementById("myChart"));
      //地图开始
      const option = {
        geo: {
          map: "China", //地图为刚刚设置的China
          aspectScale: 0.8, //长宽比
          roam: true, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启

          //地图上文字
          label: {
            normal: {
              show: false, //是否显示标签
              textStyle: {
                color: "#409eff",
                fontSize: 12,
              },
              formatter: (p) => {
                switch (p.name) {
                  case "内蒙古自治区":
                    p.name = "内蒙古";
                    break;
                  case "西藏自治区":
                    p.name = "西藏";
                    break;
                  case "新疆维吾尔自治区":
                    p.name = "新疆";
                    break;
                  case "宁夏回族自治区":
                    p.name = "宁夏";
                    break;
                  case "广西壮族自治区":
                    p.name = "广西";
                    break;
                  case "香港特别行政区":
                    p.name = "香港";
                    break;
                  case "澳门特别行政区":
                    p.name = "澳门";
                    break;
                  default:
                    break;
                }
                return p.name;
              },
            },
            emphasis: {
              textStyle: {
                color: "#409eff",
              },
            },
          },
        },
        visualMap: {
          min: 0,
          max: 20,
          left: "left",
          top: "bottom",
          text: ["高", "低"],
          calculable: false,
          orient: "horizontal",
          textStyle: {
            color: "#409eff",
          },
          inRange: {
            color: [ '#73cae1', '#fdb164' , "#b62d4b" ],
            // ['#fff','#004cff'],
            // [ "#323a97", '#b6dfec' ,'#fff', '#fdb164' , "#004cff" ],
            symbolSize: [0, 20],
          },
        },
        tooltip: {
          padding: 8,
          enterable: true,
          transitionDuration: 1,
          textStyle: {
            color: "#fff",
            decoration: "none",
          },
        },
        series: [
          {
            name: '报警数量',
            type: "map",
            geoIndex: 0,
            data: this.sosMapData
          },
        ],
      };
      myChart.setOption(option);
    },
    //初始化高德地图
    mapInitGD() {
      this.$nextTick(() => {
        mapGD = new AMap.Map("mapGD");
      });
    }
  },
  mounted() {
    myChart = echarts.init(document.getElementById("myChart"));
    const regionData = { name: "中国", adcode: 0, level: "" };
    const adminInfo = JSON.parse(sessionStorage.getItem("adminInfo"));
    if (adminInfo.manageAreaCode) {
      let arr = this.toManageAreaCode(adminInfo.manageAreaCode);
      let val = arr.length > 3 ? 2: arr.length - 1;
      console.log(val,arr,'level')
      regionData.level =
        arr.length == 1
          ? ""
          : arr.length == 2
          ? "province"
          : arr.length == 3
          ? "city"
          : "district";
          console.log(val,arr[val],regionData)
      val = arr[val];
      console.log(val,regionData)
      if (val != "") {
        val = val.toString();
        regionData.adcode = val.substring(0, 6);
        regionData.name = adminInfo.areaNames;
      }
    }
    this.initRegionData = regionData;
    this.echartMapInit(undefined, true);
  },
};
</script>
<style scoped>

.echarts-container,
#myChart,
.mapGD-container,
#mapGD {
  width: 100%;
  height: 100%;
}
</style>

