<template>
  <div class="jkBox">
    <!-- 在线 -->
    <div style="height: 400px; background: #000; display: flex; justify-content: space-around;align-items: center;" >
          <!-- <editionC2 ref="edition" :jkData='jkData' :sosDialog="sosDialog"/>
          <editionRed :jkData='jkRedData'/> -->

          <editionC2 ref="edition" :jkData='jkData' :sosDialog="sosDialog" :percentage="percentage" :eleInfoId="bikeId"
        @changePercentage="changePercentage"/>
          <el-divider direction="vertical" style="margin: auto 0; height: 80%"></el-divider>
          <editionRed :jkData='jkRedData' :percentage="percentageRed" @changePercentage="changePercentage" :eleInfoId="bikeId"/>
      </div>

      <div class="conBox">
        <el-button size="mini" @click="dropped" type="danger">
            <i class="el-icon-turn-off-microphone"></i>
            <p style="margin: 10px 0 0 0;">挂断</p>
        </el-button>
        <el-button size="mini" type="primary" @click="sosClick(2)" :loading="btnLoading">
            <span>人为触发</span>
            <p style="margin: 10px 0 0 0;">确认关闭</p>
        </el-button>
        <el-button size="mini" type="warning" @click="sosClick(3)" :loading="btnLoading">
            <span>确认火情</span>
            <p style="margin: 10px 0 0 0;">立即通知救援</p>
        </el-button>
        <el-button size="mini" class="errorSos" @click="sosClick(1)" :loading="btnLoading" >
            <span>误报</span>
            <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
        </el-button>
      </div>
  </div>
</template>

<script>
  import editionC2 from '../views/ddcp/sxtgl/components/acContainer/editionC2.vue' // 电梯监控 eleMonitoring
  import EditionRed from '../views/ddcp/sxtgl/components/acContainer/editionRed.vue';

  export default {
    props: ['deviceCode', 'bikeId', 'sosId','sosDialog'],
    components: {
      // editionC1,
      editionC2,
      EditionRed
    },
    data() {
      return {
        jkData: null,
        jkRedData: null,


        isDial: false,
        btnLoading: false,
        // sosStatus: false,
        percentage: 25,
        percentageRed: 25

      }
    },
    methods: {
      changePercentage(data, type) {
        if (type) {
          this.percentage = data
        } else {
          this.percentageRed = data
        }
      },
      getStreamStatus(type) {
        console.log(this.bikeId, "eleInfo");
        this.$http
          .get(`api/bike/web/getStreamStatus?id=${this.bikeId}&type=${type}`)
          .then((res) => {
            if (res.data.success) {
              if (type) {
                this.percentage = res.data.data ? 75 : 50;
              } else {
                this.percentageRed = res.data.data ? 75 : 50;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
    //
      openJK(type){
        let agentData = {
          act: 'ca_start_rtsp',
          device_id: this.deviceCode,
          type
        }
        this.socketCarportApi.sendCarportSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ca_start_rtsp' && e.data) {
              let data = JSON.parse(e.data)
               if (data.type == 1) {
                this.jkData = data
                this.percentage = 50;
                this.getStreamStatus(type);
              }
              if (data.type == 0) {
                this.jkRedData = data
                this.percentageRed = 50;
                this.getStreamStatus(type);
              }
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      dropped(){
        this.$nextTick(() =>{
          this.$refs.edition.dropped()
          setTimeout(() => {
              this.$emit('closed')
          }, 300)
        })
      },
      sosClick(submitType){
        this.btnLoading = true
        this.$http.post("/api/bike/web/sos/commitSos", {
            id: this.sosId,
            submitType,
        }).then((res) => {
            if (res.data.success) {
              // dioDetails
              this.btnLoading = false
              // if(type == 1){
              //   this.$confirm('确认误报成功，是否删除该报警误报记录？','提示',{
              //     cancelButtonText:'取消',
              //     confirmButtonText:'确认',
              //     type:'warning'
              //   }).then(()=>{
              //   }).catch(()=>{
              //   })
              // }
              // this.$emit('closed')
            } else {
              this.$message.error(res.data.msg)
            }
        })
      },

    },
    created() {
      console.log('我是电梯监控通话的容器')
    },
    mounted() {
        this.$nextTick(() => {
            this.openJK(0)
            this.openJK(1)
        })
    },
    destroyed() {
      console.log('监控销毁了')
    }
  }
</script>

<style scoped>
  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }
  .imgbox {
    height: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  /* div {
    width: 100%;
    height: 100%;
  } */

  .conBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }
  .jkBox {
    width: 100%;
    height: 100%;
    /* background: #000; */
  }
</style>
