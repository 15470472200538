import axios from 'axios'
import instance from '../util/http.js'
 
const isNewVersion = () => {
  axios.get(`/static/version.json?t=${new Date().getTime()}`).then(res => {
    if (res.status === 200) {
      let vueVersion = res.data.version;
      let localVueVersion = localStorage.getItem('vueVersion');
      if (localVueVersion && localVueVersion != vueVersion) {
        console.log(res.data.version,'res.data.version111111111111111');
        localStorage.setItem('vueVersion', vueVersion);
        window.location.reload();
        return;
      } else {
        localStorage.setItem('vueVersion', vueVersion);
      }
    }
  });
}
 
export default {
  isNewVersion
}