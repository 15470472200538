<template>
  <div :class="className" :style="{width:width, height:height}" />
</template>

<script>
  import echarts from 'echarts'
  import {
    formatDate
  } from "@/util";
  export default {
    props: {
      className: {
        type: String,
        default: 'chart',
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: '240px',
      },
      eleSos: {
        type: Array,
        default: () => [0, 0, 0, 0, 0],
      },
    },

    data() {
      return {
        chart: null,
      }
    },

    // 监听data里面对应的
    watch: {
      data: {
        deep: true,
        handler(val) {
          this.setOptions(val)
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart()
      })
    },

    beforeDestroy() {
      if (!this.chart) return
      // 释放图表实例
      this.chart.dispose()
      this.chart = null
    },

    methods: {
      initChart() {
        this.chart = this.$echarts.init(this.$el)
        this.setOptions(this.eleSos)
      },
      setOptions(data) {
        const options = {
          tooltip: {
            formatter: '{d}%'
          },
          series: [{
            name: 'Access From',
            type: 'pie',
            radius: '80%',
            label: {
              position: 'inner',
              fontSize: 14,
              formatter: '{d}%',
              textStyle: {
                fontWeight: 300,
                fontSize: 10 //文字的字体大小

              }
            },
            labelLine: {
              show: false
            },
            data: [{
                value: data[0],
                itemStyle: {
                  color: '#ff3939'
                }
              },
              {
                value: data[1],
                itemStyle: {
                  color: '#5470c6'
                }
              },
              {
                value: data[2],
                itemStyle: {
                  color: '#4c5058'
                }
              },
              {
                value: data[3],
                itemStyle: {
                  color: '#73c0de'
                }
              },
              {
                value: data[4],
                itemStyle: {
                  color: '#3aa526'
                }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }]

        }
        this.chart.setOption(options)

      },
    },
  }
</script>

<style>
</style>
