<template>
  <div>
    <div style="height: 389px;display: flex;align-items: center;">
      <div style="width: 76%;height: 100%;margin:auto" v-loading="loading">
        <video style="width: 100%;height: 400px;" controls autoplay :muted='isMuted' id="onPlayJkSos" />
        <video v-show="false" style="width: 100px;height: 100px;" controls autoplay id="mePlayJkSos" />
      </div>
      <div style="padding: 0px 10px;background: #025682;width: 263px;height: 381px;margin-left: -15px;margin-top: 4px;">
        <p>
          报警类型：
          <el-button type="danger" size="mini" v-if="typeDate.find(item=>item.value==dioDetailsItem.type)">
            {{typeDate.find(item=>item.value==dioDetailsItem.type).label}}
          </el-button>
        </p>
        <p :title="dioDetailsItem.name"
          style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow-y: hidden;height: 40px;cursor: pointer;">
          电梯名称：{{dioDetailsItem.name}}
        </p>
        <p>报警时间：{{dioDetailsItem.time}}</p>
        <p>报警楼层：{{dioDetailsItem.floor}}</p>
        <p>摄像头ID：{{dioDetailsItem.code}}</p>
        <p :title="dioDetailsItem.plotName"
          style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow-y: hidden;height: 22px;cursor: pointer;">
          小区名称：{{dioDetailsItem.plotName}}
        </p>
        <div style="display: flex;justify-content: space-between;">
          <p style="word-break: keep-all;">维保人员：</p>
          <div v-if="dioDetailsItem.maintainUser.split(',').length > 0">
            <div v-for="(item,index) in dioDetailsItem.maintainUser.split(',')">
              <p v-if="index < 2" style="white-space: nowrap;">
                {{item}}
                <i class="el-icon-document-copy copyInfo" @click="Copy(item)"></i>
              </p>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <p style="word-break: keep-all;">物业人员：</p>
          <div v-if="dioDetailsItem.propertyUser.split(',').length > 0">
            <div v-for="(item,index) in dioDetailsItem.propertyUser.split(',')">
              <p v-if="index < 2" style="white-space: nowrap;">
                {{item}}
                <i class="el-icon-document-copy copyInfo" @click="Copy(item)"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="conBox">
      <!-- <el-button @click="dialZlv" title="拨打" type="success" icon="el-icon-microphone">拨打</el-button> -->
      <el-button size="mini" v-loading="btnLoading" @click="dropped" type="danger">
        <i class="el-icon-turn-off-microphone"></i>
        <p style="margin: 10px 0 0 0;">挂断</p>
      </el-button>

      <el-button :disabled='sosStatus' size="mini" type="primary" @click="sosClick(1)" :loading="btnLoading">
        <span>人为触发(无故障)</span>
        <p style="margin: 10px 0 0 0;">确认关闭</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="info" @click="sosClick(2)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 98&&dioDetailsItem.type != 21">
        <span>电梯故障(无困人)</span>
        <p style="margin: 10px 0 0 0;">派发维修工单</p>
      </el-button>
      <el-button
        v-if="dioDetailsItem.type != 109&& dioDetailsItem.type != 21 &&dioDetailsItem.type != 19 &&dioDetailsItem.type != 20 && dioDetailsItem.type != 15&&dioDetailsItem.type != 13&& dioDetailsItem.type != 96"
        :disabled='sosStatus' size="mini" type="warning" @click="sosClick(3)" :loading="btnLoading">
        <span>困人</span>
        <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
      </el-button>
      <el-button size="mini" class="errorSos" @click="sosClick(5)" :loading="btnLoading" :disabled="sosStatus"
        v-if="dioDetailsItem.type != 98">
        <span>误报</span>
        <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
      </el-button>
      <!-- <el-button  @click="dialZlv" title="关闭" type="info" icon="el-icon-microphone">关闭</el-button> -->
    </div>
  </div>
</template>

<script>
  let obj = []
  let one = true
  var userAgent = null
  export default {
    props: ['jkData', 'sosId', 'alarmTime', 'videoStatusId', 'ids', 'dioDetailsItem'],
    data() {
      return {
        typeDate: [],
        timer: null,
        timeCount: null,
        // 监控
        player: null,
        loading: true,
        timeWebRtc: null,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        // 通话
        isMuted: true,
        meData: [],
        meObj: null,
        sosStatus: false,

        playTime: 0, // 通话时间
        playWebrtc: '',
        btnLoading: false,

        type: 1,
        btnLoading: true
      }
    },
    created() {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item) => {
              if (this.adminInfo.userName != 'admin') {
                return item.value != '21' && item.value != '35'
              } else {
                return item
              }
            })
            this.typeDate = Arr
          }
        })
    },
    mounted() {
      let start = 30
      this.timeCount = setInterval(() => {
        start--
        // console.log(this.timer, '成功')
        if (start > -1) {
          if (this.timer == '失败' || this.timer == null) {
            if (start < 1) {
              this.getTimes(2)
            }
          } else {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        } else {
          if (this.timer == '成功') {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        }
      }, 1000)
      obj = JSON.parse(sessionStorage.getItem('me_id')).sip_info
      setTimeout(() => {
        this.init()
        this.dialZlv()
      }, 1000)
    },
    beforeDestroy() {
      // if (this.player) {
      // 	// this.player.destroy()
      // 	this.player = null
      // }
      clearInterval(this.timeCount)
      if (this.jkData) {
        let agentData = {
          act: 'ma_stop_webrtc_call',
          sos_room_id: this.jkData.device_id,
        }
        this.socketApi.sendSock(agentData, (e) => {

        })
        console.log('监控组件销毁了123')
        this.dropped()
        this.stop()
      }
    },

    methods: {
      useCopyToClipboard(val) {
        let isSuccessRef = false;

        //创建input标签
        var input = document.createElement("input");
        //将input的值设置为需要复制的内容
        input.value = val;
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        if (document.execCommand("copy")) {
          isSuccessRef = true;
        } else {
          isSuccessRef = false;
        }
        //移除input标签
        document.body.removeChild(input);

        return {
          isSuccessRef
        };
      },
      Copy(str) { //复制
        const one = str.split('(')[1]
        const data = one.split(')')[0]
        console.log(data, 'str1')
        let {
          isSuccessRef
        } = this.useCopyToClipboard(data)
        if (isSuccessRef) {
          this.$message({
            type: 'success',
            message: '复制成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: '复制失败'
          })
        }
      },
      // 详情操作按钮
      sosClick(type) {
        if (type != 4) {
          this.btnLoading = true
          this.sosStatus = true
        }
        this.$http
          .post('/api/ele/app/sos/submit', {
            id: this.sosId,
            submitType: type,
            sosTime: this.alarmTime
          })
          .then((res) => {
            if (type != 4) {
              if (res.data.success) {
                this.btnLoading = false
                // if (type == 5) {
                //   this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                //     cancelButtonText: '取消',
                //     confirmButtonText: '确认',
                //     type: 'warning'
                //   }).then(() => {
                //     this.$http
                //       .post("/api/ele/web/sos/removeSosInfo", {
                //         id: this.sosId,
                //         sosTime: this.alarmTime,
                //       }).then((res) => {
                //         if (res.data.code == 200) {
                //           this.$message.success(res.data.msg)
                //         } else {
                //           this.$message.error(res.data.msg)
                //         }
                //       })
                //   }).catch(() => {})
                // }
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            }
            // this.btnLoading = false
          })
      },
      dialZlv() {
        let agentData = {
          v_type: 0,
          act: 'ma_set_sip_info',
          deviceId: this.jkData.device_id
        }
        this.socketApi.sendSock(agentData, (e) => {
          // 下列参数写死
          var host = obj.sip_host; // 域名
          var user = obj.sip_id; // 注册人id
          var pwd = '0000'; // 密码
          var wss = obj.wss_url; // 长连接地址
          var iceHost = obj.stun_host; // stun地址
          var turnUser = obj.turn_user; // turn用户名
          var turnPwd = obj.turn_pwd; // turn密码

          var config = {
            uri: user + '@' + host,
            transportOptions: {
              wsServers: [wss]
            },
            authorizationUser: user,
            password: pwd,
            sessionDescriptionHandlerFactoryOptions: {
              peerConnectionOptions: {
                rtcConfiguration: {
                  iceServers: [{
                      urls: 'stun:' + iceHost
                    },
                    {
                      urls: 'turn:' + iceHost,
                      username: turnUser,
                      credential: turnPwd
                    }
                  ]
                }
              }
            }
          };
          userAgent = new SIP.UA(config);
          userAgent.on('registered', function() {});
          userAgent.on('invite', function(session) {
            var url = session.remoteIdentity.uri.toString() + "--->call";
            //接受来电
            session.accept({
              sessionDescriptionHandlerOptions: {
                constraints: {
                  audio: true,
                  // video: {
                  // 	width: 1280,
                  // 	height: 720
                  // }
                }
              }
            });
            this.sipsession = session;
          });

          setTimeout(() => {
            var hostc = obj.sip_host;
            var toc = this.jkData.sip_id;
            console.log('我等了两秒', this.jkData)
            this.sipsession = userAgent.invite(toc + '@' + hostc, {
              sessionDescriptionHandlerOptions: {
                constraints: {
                  audio: true,
                  // video: {
                  // 	faceMode: 'user', //facemode是一个选择摄像头的约束，在移动设备上效果很好，有user（用户，前置摄像头）、environment（环境，后置摄像头）、left、right四种选项
                  // 	width: 160,
                  // 	height: 120,
                  // 	frameRate: 16,
                  // }
                }
              }
            });
            let _this = this
            this.sipsession.on('accepted', function() {
              console.log('接通了')
              _this.btnLoading = false
              // 按钮禁点2.5秒防止对象未操作就挂断
              var pc = _this.sipsession.sessionDescriptionHandler.peerConnection;
              var remoteStream = new MediaStream();
              pc.getReceivers().forEach(function(receiver) {
                remoteStream.addTrack(receiver.track);
              });
              var mePlayJk = document.getElementById('mePlayJkSos');
              mePlayJk.srcObject = remoteStream;
              mePlayJk.play();
            });
            this.sipsession.on('rejected', (response, cause) => {
              console.log('onRejected - ', response, cause)
            });
            this.sipsession.on('failed', (response, cause) => {
              console.log('onFailed - ', response, cause)
            });
            this.sipsession.on('terminated', (message, cause) => {
              console.log('onTerminated - ', message, cause)
            });
          }, 2000)
        })
      },
      dropped() {
        let agentData = {
          act: 'ma_stop_webrtc_call',
          sos_room_id: this.jkData.device_id,
        }
        this.socketApi.sendSock(agentData, (e) => {})
        this.$emit('bclose')
        if (this.sipsession) this.sipsession.terminate();
      },
      // 重连
      opJk() {
        this.stop()
        let agentData = {
          act: 'ca_start_rtsp',
          device_id: this.jkData.device_id,
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ca_start_rtsp') {
              this.init()
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      //关闭监控
      stop() {
        clearInterval(this.timeWebRtc)
        if (this.player) {
          this.player.close()
          this.player = null
          var local = document.getElementById('onPlayJkSos')
          if (local) {
            local.srcObject = null
            local.load()
          }
        }
      },
      // 监控播放400, 查看监控播放状态
      onFail() {
        setTimeout(() => {
          if (this.player) {
            // this.player.destroy()
            this.player = null
          }
          let agentData = {
            act: 'ca_start_rtsp',
            device_id: this.jkData.device_id
          }
          this.socketApi.sendSock(agentData, (e) => {
            if (e.status) {
              if (e.cmd == 'ca_start_rtsp') {
                this.init()
              }
            } else {
              this.$message.error('流文件信息或长连接错误！')
            }
          })
        }, 4 * 1000)
      },
      // 监控开始播放
      startPlay() {
        this.loading = false
      },
      //更新视频时间
      getTimes(type) {
        const eleId = this.videoStatusId ? this.vieoStatusId : this.ids
        const time = new Date(new Date()).getTime()
        // if (this.videoStatusId) {
          this.$http.post(
            `/api/ele/web/eleVideo/updateElevatorVideoStatus?id=${eleId}&videoTime=${time}&videoStatus=${type}`
          ).then((res) => {
            console.log(res, 'res')
            this.$emit('update', time, type)
          })
        // } else {
        //   this.$http.post(
        //     `/api/ele/web/eleVideo/updateElevatorVideoStatus?elevatorId=${this.ids}&videoTime=${time}&videoStatus=${type}`
        //   ).then((res) => {
        //     this.$emit('update', time, type)
        //     console.log(res, 'res')
        //   })
        // }
      },
      init() {
        const that = this
        const jswebrtc = document.getElementById(`onPlayJkSos`)
        console.log(this.jkData, 'openUrl');
        const openUrl = this.jkData.play_url
        const playUrl = openUrl[openUrl.length - 2]

        this.player = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: playUrl, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: false,
          videoEnable: false,
          recvOnly: true, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480
          }
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
          console.log('ICE 协商出错')
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
          that.timer = '成功'
          console.log('播放成功', e.streams)
          clearInterval(that.timeWebRtc)
          that.loading = false

          // that.dialZlv()
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
          e) { // offer anwser 交换失败
          that.timer = '失败'
          console.log('offer anwser 交换失败', e)
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
          jswebrtc.srcObject = s;
          jswebrtc.muted = false;
          that.timer = '成功'
          console.log('获取本地流成功--拉流')
        });
        this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
          console.log('获取本地流失败--拉流')
          that.timer = '失败'
        });
      },
    },
  }
</script>

<style scoped>
  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .conBox {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    /* width: 6%; */
    height: 100%;
    margin-top: 15px;
  }
</style>
