<template>
  <div
    :class="className"
    :style="{width:width, height:height}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  // mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '50vh',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    dataName: {
      type: String,
      default: 'oldV',
    },
    playTime: {
      type: Number,
      default: 5000,
    },
    sosLineData: {
      type: Object,
      default: () => {
        return {
          data: [],
          time: [],
        }
      },
    },
  },

  data() {
    return {
      chart: null,
      lineColor: ['#5793f3', '#f2544e']
    }
  },

  // 监听data里面对应的
  watch: {
    sosLineData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions({
      data,
      time,
      oldV,
      outSpeed
    } = {}) {
      let dataCharts = []
      if (this.dataName == 'oldV') {
        dataCharts = [{
          name: 'oldV',
          data: oldV
        }, {
          name: 'outSpeed',
          data: outSpeed
        }]
      } else {
        dataCharts = [{
          name: this.dataName,
          data
        }]
      }

      const options = {
        color: ['#5793f3', '#f2544e'],
        animationDuration: this.playTime,
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          top: '25px',
          data: this.dataName == 'oldV' ? [{
            name: 'oldV',
            textStyle: {
              color: this.lineColor[0]
            }
          }, {
            name: 'outSpeed',
            textStyle: {
              color: this.lineColor[1]
            }
          }] : [{
            name: this.dataName,
            textStyle: {
              color: this.lineColor[0]
            }
          }]
        },
        grid: {
          top: 70,
          bottom: 50,
          right: 10,
          left: 80
        },
        dataZoom: [
          // 下层拖拽缩放
          {
            type: 'slider',
            xAxisIndex: 0,
            startValue: 0,
            endValue: 100000, // 只显示7个数据
            bottom: 0,
            height: 15
          },
          // 图表内也可缩放
          {
            type: 'inside',
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#5793f3'
              }
            },
            data: time
          },
          {
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 'dataMin', // 最小值
            scale: true /* 按比例显示*/,
            axisLine: {
              lineStyle: {
                color: '#5793f3'
              }
            }
          }
        ],
        series: dataCharts.map((item, index) => {
          console.log(item.name, 'item.name');
          return {
            name: item.name,
            data: item.data,
            type: 'line',
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: this.lineColor[index] // 线的颜色
                }
              }
            }
          }
        })
        // series: [
        //   {
        //     name: this.dataName,
        //     type: 'line',
        //     xAxisIndex: 1,
        //     smooth: true,
        //     data,
        //   },
        // ],
      }
      this.chart.setOption(options)
    },
  },
}
</script>
