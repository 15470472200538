<template>
	<div id="qbg" class="tbdod">
		<div id="particles-js" class="bgcolo"></div>
		<!-- 弹框 -->
		<MonitorInfo ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo' :elevatorId="elevatorId"
			:deviceCode="deviceCode" @closeMonitor="closeMonitor" />
		<div class="topBg">
			<div class="topBgBox2"></div>
			<div class="topBgBoxCent">
				<div class="top">
					<el-image v-if="$store.state.isZx" style="width: 38px; height: 38px;margin: 0 20px;"
						:src="require('@/assets/zhongxin.png')" fit="cover" />
					<h2 class="title">
						<span v-if="$store.state.isZx">中信</span>数字化电梯物联网<span v-if="!$store.state.isZx">安全</span>监管系统
					</h2>
				</div>
			</div>
			<div class="topBgBox1"></div>
			<el-button class="titBut" size="small" type="primary" @click="back" v-text="'回到平台'" />

			<!-- <el-button size="small" type="primary" @click="isCar=1" v-text="'回到平台1'" />
			<el-button size="small" type="primary" @click="isCar=2" v-text="'回到平台2'" />
			<el-button size="small" type="primary" @click="isCar=3" v-text="'回到平台3'" /> -->
		</div>

		<!-- 左 -->
		<transition name="fade" appear enter-active-class="animate__animated animate__backInLeft"
			leave-active-class="animate__animated animate__backOutLeft">
			<div class="leftBox" v-show="isOnPage==1">

				<transition-group name="fade" mode="out-in" enter-active-class="animate__animated animate__backInLeft"
					leave-active-class="animate__animated animate__backOutLeft">
					<div class="leftBox-a" v-show="isCar==0||isCar==1" key="1">
						<div class="boxImg">
							<span>电梯风险占比</span>
						</div>
						<div id="leftone1"></div>
					</div>
					<div class="leftBox-a" v-show="isCar==0||isCar==2" key="2">
						<div class="boxImg">
							<span>电梯年龄分布</span>
						</div>
						<div id="leftone2"></div>
					</div>
					<div class="leftBox-a" v-show="isCar==0||isCar==3" key="3">
						<div class="boxImg">
							<span>品牌占比</span>
						</div>
						<div id="leftone3"></div>
					</div>
				</transition-group>

				<div class="leftBox-a" style="top: 34%;">
					<div class="boxImg">
						<span>维保数据(月)</span>
					</div>
					<div id="leftTwo"></div>
				</div>

				<transition-group name="fade" mode="out-in" enter-active-class="animate__animated animate__backInLeft"
					leave-active-class="animate__animated animate__backOutLeft">
					<div class="leftBox-a" v-show="isCar==0||isCar==1||isCar==3" style="top: 68%;" key="1">
						<div class="boxImg">
							<span>高频报警分布</span>
						</div>
						<div id="unitInfo1"></div>
					</div>
					<div class="leftBox-a" v-show="isCar==0||isCar==2" style="top: 68%;" key="2">
						<div class="boxImg">
							<span>维保评分</span>
						</div>
						<div id="unitInfo2"></div>
					</div>
				</transition-group>

			</div>
		</transition>
		<!-- 中上 -->
		<transition name="fade" appear enter-active-class="animate__animated animate__backInDown"
			leave-active-class="animate__animated animate__backOutUp">
			<div class="zTopBox" v-show="isOnPage==1">
				<el-carousel arrow="never" indicator-position="outside" height="120px" :interval='10000'>
					<el-carousel-item v-for="item in 2" :key="item">
						<h3 v-show="item==1">
							<div class="topBut">
								<span class="topButSp2">维保人员</span>
								<span class="topButSp1">{{topData.maintainNum}}</span>
							</div>
							<div class="topBut">
								<span class="topButSp2">设备投保比例</span>
								<span class="topButSp1">{{topData.contractNum}}</span>
							</div>
							<div class="topBut">
								<span class="topButSp2">应急中设备</span>
								<span class="topButSp1">{{topData.rescueTaskCount}}</span>
							</div>
						</h3>
						<h3 v-show="item==2">
							<div class="topBut">
								<span class="topButSp2">今日故障电梯</span>
								<span class="topButSp1">{{topData.failureTaskCount}}</span>
							</div>
							<div class="topBut">
								<span class="topButSp2">应急平均时长</span>
								<span class="topButSp1">{{topData.eleRescueTaskTime}}</span>
							</div>
							<div class="topBut">
								<span class="topButSp2">报警平均响应时长</span>
								<span class="topButSp1">{{topData.sosAvgTime}}</span>
							</div>
						</h3>
					</el-carousel-item>
				</el-carousel>
			</div>
		</transition>

		<!-- 中下 -->
		<div class="zBomBox">
			<!-- .zbomBut -->
			<div class="zbomBut" @click="cut(1)">首 页</div>
			<div class="zbomBut" @click="cut(2)">监管单位</div>
			<div class="zbomBut" @click="cut(3)">维保单位</div>
		</div>
		<!-- 左 -->
		<transition name="fade" appear enter-active-class="animate__animated animate__backInRight"
			leave-active-class="animate__animated animate__backOutRight">
			<div class="rightBox" v-show="isOnPage==1">
				<div class="leftBox-a">
					<div class="boxImg">
						<span>电梯总数据</span>
					</div>
					<div class="wdrop" v-if="isBinShow">
						<div class="binA">
							<bin1 :information="binData1" />
						</div>
						<div class="binA">
							<bin1 :information="binData2" />
						</div>
						<div class="binA">
							<bin1 :information="binData3" />
						</div>
					</div>
				</div>

				<transition-group name="fade" mode="out-in" enter-active-class="animate__animated animate__backInRight"
					leave-active-class="animate__animated animate__backOutRight">
					<div class="leftBox-a" v-show="isCar==0||isCar==1||isCar==3" style="top: 34%;" key="1">
						<div class="boxImg">
							<span>维保单位排比</span>
						</div>
						<div class="wbScoll scrollElement">
							<p v-for="(item,ind) in wbList" :title="item">
								<span>NO{{ind+1}}</span>
								{{item}}
							</p>
						</div>
					</div>
					<div class="leftBox-a" v-show="isCar==0||isCar==2" style="top: 34%;" key="2">
						<div class="boxImg">
							<span>单位统计</span>
						</div>
						<div class="wbScoll scrollElement">
							<p v-for="(item,ind) in dwList" :title="item">
								<span>NO{{ind+1}}</span>
								{{item}}
							</p>
						</div>
					</div>
				</transition-group>



				<div class="leftBox-a" style="top: 68%;">
					<div class="boxImg">
						<span>年度事故统计</span>
					</div>
					<div id="highSos"></div>
				</div>
			</div>
		</transition>

		<div id="allmap"></div>

		<transition name="fade" appear leave-active-class="animate__animated animate__zoomOut">
			<img v-if="isLodin" class="lodinBack" src="@/assets/lodin.gif" />
		</transition>

		<!-- 报警弹窗 -->
    <audio
      id="alarmA"
      :src="require('@/assets/alarm.mp3')"
      loop="true"
    />
    <div class="sosCall">
      <template v-for="(item, index) in sosTypeList">
        <div
          class="sosCallBox"
          :key="index"
        >
          <span>{{item.sosName}}</span>
          <div class="sosMsg">
            电梯名称：
            {{item.name}}
          </div>
          <div class="sosMsg">
            电梯位置：
            {{item.address}}
          </div>
          <div style="margin-top: 10px;">
            <template v-if="item.c_id">
              <el-button
                type="info"
                size="small"
                @click="closeSOS(item)"
              >关闭({{item.time}})</el-button>
            </template>
            <template v-else>
              <el-button
                type="primary"
                size="small"
                @click="answerSOS(item)"
              >接听({{item.time}})</el-button>
              <el-button
                type="info"
                size="small"
                @click="closeSOS(item)"
              >拒接</el-button>
            </template>
          </div>
        </div>
      </template>
      <el-dialog
        v-if="visible"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :show-close="showClose"
        width="950px"
        top="8vh"
        :modal="false"
        @closed="bclose"
      >
        <div
          style="padding: 0 20px"
          class="scrollElement"
        >
          <EditionC2
            :jkData='jkData'
            :sosId="sosId"
            @bclose="bclose"
          />
        </div>
        <div
          slot="footer"
          class="dialog-footer"
        >
        </div>
      </el-dialog>
    </div>

	</div>
</template>

<script>
	import 'animate.css';
	let map
	let isOne = true
	let markers = []
	let getDeTime
	let sosTime
	import bin1 from './com/bin.vue';
	import * as echarts from 'echarts';
	import MonitorInfo from '../dtsj/dtjk/components/monitorInfo.vue'
	import EditionC2 from '../../components/editionC2'
	export default {
		data() {
			return {
				// 加载动画
				isLodin: true,
				// 顶部数据
				topData: [],
				// 单位统计和维保单位排比
				dwList: [],
				wbList: [],
				//
				carBo: null,
				// 页面切换
				isCar: 0,
				isOnPage: 1,
				upLeftLst: [{
					name: 'leftone1',
					data: null
				}, {
					name: 'leftone2',
					data: null
				}, {
					name: 'leftone3',
					data: null
				}],
				loLfetList: [{
					name: 'unitInfo1',
					data: null
				}, {
					name: 'unitInfo2',
					data: null
				}],
				// 弹框数据
				eleInfo: null, //总数据
				elevatorId: '', // 电梯id
				deviceCode: '', // 设备号
				MonitorInfoVisible: false,
				// 右上角饼图
				isBinShow: false,
				binData1: {
					name: '在线',
					value: '',
					tit: '在线电梯数'
				},
				binData2: {
					name: '故障',
					value: '',
					tit: '投保电梯故障率'
				},
				binData3: {
					name: '故障',
					value: '',
					tit: '未投保电梯故障率'
				},
				// 报警信息
				sosTypeList: [],
				jkData: null, // 球机推拉流
				visible: false,
				sosId: '',
				showClose: false,
				adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
			};
		},
		components: {
			bin1,
			MonitorInfo,
			EditionC2
		},

  created() {
    this.$http
      .post('/api/system/web/dict/dictionary?codes=BUTTON_TYPE')
      .then((res) => {
        if (res.data.success) {
          sessionStorage.setItem(
            'BUTTON_TYPE',
            JSON.stringify(res.data.data.BUTTON_TYPE)
          )
        }
      })

    this.$http
      .post('/api/system/web/dict/dictionary?codes=SOS_TYPE')
      .then((res) => {
        if (res.data.success) {
			const Arr = res.data.data.SOS_TYPE.filter((item)=>{
              if(this.adminInfo.userName != 'admin'){
                return item.value != '21'&&item.value != '35'
              }else{
                return item
              }
            })
          this.tpList = Arr
          sosTime = setInterval(() => {
            // this.sosTypeList
            this.sosTypeList.forEach((qs, ind) => {
              qs.time -= 1
              if (qs.time <= 0) {
                this.sosTypeList.splice(ind, 1)
              }
            })

            this.socketApi.sosPrompt.forEach((qs) => {
              qs.time = 30
              qs.typeShow = true
              if (qs.type === -1) {
                qs.sosName = `${qs.terminalSosName}报警`
              } else {
                qs.sosName = this.getType(qs.type)
              }
              this.sosTypeList.push(qs)
            })
            if (this.sosTypeList.length > 0 && !this.visible && !this.$global.openJK) {
              document.getElementById('alarmA').loop = true
              document.getElementById('alarmA').play()
            } else {
              document.getElementById('alarmA').pause()
            }

            this.socketApi.clearSos()
          }, 1000)
        }
      })
  	},
		mounted() {
			this.$nextTick(() => {
				openLz();
				setTimeout(() => {
					map = new AMap.Map('allmap', {
						mapStyle: "amap://styles/grey",
						rotateEnable: true,
						pitchEnable: true,
						zoom: 18,
						pitch: 50,
						rotation: -15,
						viewMode: '3D', //开启3D视图,默认为关闭
						zooms: [2, 20],
						center: [116.333926, 39.997245]
					})
					setTimeout(() => {
						this.getFacility()
						getDeTime = setInterval(() => {
							this.getFacility()
						}, 59000)

						this.isLodin = false
					}, 2000)
				}, 2000)


				this.$http
					.get("/api/ele/web/elevatorInfo/getAllEleData")
					.then((res) => {
						if (res.data.success) {
							this.isBinShow = true
							console.log('qwdqd', res)
								let obj2 = res.data.data.notContractEleData
							if(obj2.faultContractEle!=0 || obj2.normalContractEle!=0){
								let num2 = obj2.faultContractEle / (obj2.normalContractEle + obj2.faultContractEle)
								num2 = (num2 * 100).toFixed(2)
								this.binData3.value = num2 + '%'
							}else{
								this.binData3.value = '0%'
							}

							let obj1 = res.data.data.contractEleData
							if(obj1.faultContractEle!=0 || obj1.normalContractEle!=0){
								let num1 = obj1.faultContractEle / (obj1.normalContractEle + obj1.faultContractEle)
								num1 = (num1 * 100).toFixed(2)
								this.binData2.value = num1 + '%'
							}else{
								this.binData2.value = '0%'
							}

							this.binData1.value = res.data.data.eleOnlineData.onlineCount
						}
					});

				//顶部数据
				this.$http
					.get("/api/ele/web/elevatorInfo/getEleTopData")
					.then((res) => {
						if (res.data.success) {
							this.topData = res.data.data
						}
					});


				// 保险电梯故障率

				this.$http
					.get("/api/ele/web/elevatorInfo/getEleAgeCount")
					.then((res) => {
						if (res.data.success) {
							res.data.data.forEach(qs=>{
								qs.value = qs.value*10
							})
							this.upLeftLst[1].data = res.data.data
						}
						this.$http
							.get("/api/ele/web/elevatorInfo/getProportionOfBrands")
							.then((res) => {
								if (res.data.success) {
									res.data.data.forEach(qs=>{
										qs.value = qs.value*10
									})
									this.upLeftLst[2].data = res.data.data
								}
								this.upLeftLst.forEach((res) => {
									this.getOne(res)
								})
							});
					});

				const myDate = new Date();
				const Y = myDate.getFullYear();
				const M = myDate.getMonth() + 1;

				let timeData = {
					searchStartTime: [new Date(Y, M - 6, 1).getTime(), new Date(Y, M, 0).getTime()]
				}
				this.$http
					.post("/api/ele/web/maintainTask/getMonthCount", timeData)
					.then((res) => {
						if (res.data.success) {
							// 维保数据
							this.getNd(res.data.data.data)
							// this.dwList = res.data.data
						}
					});

				let CountTime = {
					start: new Date(Y, M - 6, 1).getTime(),
					end: new Date(Y, M, 0).getTime()
				}
				this.$http
					.post("/api/ele/web/sos/getTaskCount", CountTime)
					.then((res) => {
						if (res.data.success) {
							// 年度事故趋势
							this.getHighSos(res.data.data)
							// this.dwList = res.data.data
						}
					});

				this.$http
					.post("/api/ele/web/sos/getTypeCount", CountTime)
					.then((res) => {
						if (res.data.success) {
							this.loLfetList[0].data = res.data.data
							// 单位统计
							this.loLfetList.forEach((res) => {
								this.getUnitInfo(res)
							})
							// this.dwList = res.data.data
						}
					});

				// this.getAnnual()
				setTimeout(() => {
					this.isCar = 1
				}, 500)
				this.carBo = setInterval(() => {
					this.isCar++
					if (this.isCar > 3) {
						this.isCar = 1
					}
					clearInterval(this.timer);
					this.carBo
				}, 10000)

				this.$http
					.get("/api/system/web/org/getOrgCount")
					.then((res) => {
						if (res.data.success) {
							this.dwList = res.data.data
						}
					});

				this.$http
					.get("/api/ele/web/maintainTask/getMaintainList")
					.then((res) => {
						if (res.data.success) {
							this.wbList = res.data.data
						}
					});
			});

		},
		beforeDestroy() {
			clearInterval(this.carBo)
		clearInterval(sosTime)
		},
		methods: {
			getType(type) {
				let obj = '未知报警'
				this.tpList.forEach((qs) => {
					if (qs.value == type) {
						obj = qs.label
						return obj
					}
				})
				return obj
			},
			// 接听
			answerSOS(data) {
				this.sosTypeList = this.sosTypeList.filter(
					(item) => item.sosId !== data.sosId
				)
				document.getElementById('alarmA').pause()
				this.sosId = data.sosId
				this.visible = true
				setTimeout(() => {
					this.showClose = true
				}, 2000)
				const agentData = {
					act: 'ca_start_rtsp',
					device_id: data.code,
				}
				this.socketApi.sendSock(agentData, (e) => {
					if (e.status) {
						if (e.cmd === 'ca_start_rtsp') {
							const data = JSON.parse(e.data)
							console.log('监控data', data)
							this.jkData = data
						}
					} else {
						this.$message.error(e.msg)
					}
				})
				console.log(agentData, 'agentData')
			},
			bclose() {
				this.visible = false
			},
			// 拒接
			closeSOS(data) {
				this.sosTypeList = this.sosTypeList.filter(
					(item) => item.sosId !== data.sosId
				)
				if (this.sosTypeList.length === 0) {
					document.getElementById('alarmA').pause()
				}
			},
			cut(type) {
				// this.isOnPage = type
				this.isOnPage = 0
				setTimeout(() => {
					this.isOnPage = 1
				})
			},
			closeMonitor() {
				this.MonitorInfoVisible = false
			},
			getStatus(status) {
				let obj
				switch (status) {
					case 0:
						obj = {
							lab: '离线',
							color: '#4c4c4c',
							icon: require('@/assets/mapIcon/lx.svg')
						}
						break;
					case 1:
						obj = {
							lab: '在线',
							color: '#4c4c4c',
							icon: require('@/assets/mapIcon/zc.svg')
						}
						break;
					case 2:
						obj = {
							lab: '发生了报警',
							color: '#ffa0a0',
							icon: require('@/assets/mapIcon/bj.svg')
						}
						break;
					case 3:
						obj = {
							lab: '故障',
							color: '#ff6666',
							icon: require('@/assets/mapIcon/gz.svg')
						}
						break;
					case 4:
						obj = {
							lab: '即将年检',
							color: '#ffbe66',
							icon: require('@/assets/mapIcon/nj.svg')
						}
						break;
					case 5:
						obj = {
							lab: '即将合同到期',
							color: '#759119',
							icon: require('@/assets/mapIcon/dq.svg')
						}
						break;
				}
				return obj
			},
			getFacility() {
				// if (this.socketApi.isheart) {
				// 	let agentData = {
				// 		act: 'ma_get_ele_distribution',
				// 		token: sessionStorage.getItem('AdminToken')
				// 	}
				// 	this.socketApi.sendSock(agentData, (e) => {
				// 		if (e.cmd == 'ma_get_ele_distribution' && e.status) {
				// 			let data = JSON.parse(e.data)
				// 			this.eleDistri = data.eleDistributionTop
				// 			this.liftArr = data.eleList
				// 			this.setMapMarker(data.eleList)
				// 		}
				// 	})
				// }
				this.$http
					.post(`/api/ele/web/elevatorInfo/getEleDistribution`, {})
					.then((res) => {
					if (res.data.success) {

						this.eleDistri = res.data.data.eleDistributionTop
						this.liftArr = res.data.data.eleList
						this.setMapMarker(res.data.data.eleList)
					}
				})
			},
			setMapMarker(data) {
				map.remove(markers)
				map.clearMap()
				data.forEach((qs) => {
					if (qs.latitude && qs.longitude) {
						let isStatus = this.getStatus(qs.status)

						var icon = new AMap.Icon({
							size: new AMap.Size(40, 40), // 图标尺寸
							image: isStatus.icon, // Icon的图像
							imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
							imageSize: new AMap.Size(40, 40) // 根据所设置的大小拉伸或压缩图片
						});

						var marker = new AMap.Marker({
							position: [
								qs.longitude,
								qs.latitude
							], //位置
							icon: icon, //自定义中端帽子的图标
						})
						// 设置标题
						marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
						markers.push(marker)
						map.add(marker) //添加到地图

						let content = '<div style="background-color:' + isStatus.color +
							';padding: 10px;color:white;">' + qs.name + '(' + isStatus.lab + ')' +
							'<br/>AI摄像头ID:' + qs.deviceCode +
							'<br/>救援识别码:' + qs.rescueCode +
							'<br/>电梯注册代码:' + qs.code +
							'<br/>安装位置:' + qs.eleAddress + '</div>'

						let infoWindow = new AMap.InfoWindow({
							//创建信息窗体
							content: content,
							offset: new AMap.Pixel(10, -40),
						})

						var onMarkerOver = function(e) {
							infoWindow.open(map, e.target.getPosition()) //打开信息窗体
						}
						var onMarkerOut = function() {
							infoWindow.close()
						}
						marker.on('mouseover', onMarkerOver) //绑定mouseover事件
						marker.on('mouseout', onMarkerOut) //绑定mouseout事件
						let that = this
						var onMarkerClick = function(e) {
							that.eleInfo = qs
							that.elevatorId = qs.id
							that.deviceCode = qs.deviceCode
							that.MonitorInfoVisible = true
						}
						marker.on('click', onMarkerClick) //绑定click事件
					}
				})
				if (isOne) {
					isOne = false
					map.setFitView()
				}
			},
			getAnnual() {
				// var myChart = echarts.init(document.getElementById('Annual'));
				// var options = {};
				// myChart.setOption(options)

			},
			getHighSos(data) {
				let colorList = [
					['rgba(0,179,244,0.3)', 'rgba(0,179,244,0)', 'rgba(0,179,244, 0.9)'],
					['rgba(0,202,149,0.3)', 'rgba(0,202,149,0)', 'rgba(0,202,149, 0.9)']
				]
				let series1 = []
				data.titData.forEach((qs, ind) => {
					let obj = {
						name: qs,
						type: 'line',
						smooth: true, //是否平滑
						showAllSymbol: true,
						// symbol: 'image://./static/images/guang-circle.png',
						symbol: 'circle',
						symbolSize: 15,
						lineStyle: {
							normal: {
								color: colorList[ind][2],
								shadowColor: 'rgba(0, 0, 0, .3)',
								shadowBlur: 0,
								shadowOffsetY: 5,
								shadowOffsetX: 5,
							},
						},
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: colorList[ind][2],
							}
						},
						itemStyle: {
							color: colorList[ind][2],
							borderColor: "#fff",
							borderWidth: 3,
							shadowColor: 'rgba(0, 0, 0, .3)',
							shadowBlur: 0,
							shadowOffsetY: 2,
							shadowOffsetX: 2,
						},
						tooltip: {
							show: true
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: colorList[ind][0]
									},
									{
										offset: 1,
										color: colorList[ind][1]
									}
								], false),
								shadowColor: colorList[ind][2],
								shadowBlur: 20
							}
						},
						data: data.dataList[ind]
					}
					series1.push(obj)
				})

				var myChart = echarts.init(document.getElementById('highSos'));
				var options = {
					legend: {
						icon: 'rect',
						itemWidth: 14,
						itemHeight: 5,
						itemGap: 13,
						right: '4%',
						data: data.titData,
						textStyle: {
							fontSize: 12,
							color: '#F1F1F3'
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							lineStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
										offset: 0,
										color: 'rgba(0, 255, 233,0)'
									}, {
										offset: 0.5,
										color: 'rgba(255, 255, 255,1)',
									}, {
										offset: 1,
										color: 'rgba(0, 255, 233,0)'
									}],
									global: false
								}
							},
						},
					},
					grid: {
						icon: 'rect',
						top: '15%',
						left: '5%',
						right: '5%',
						bottom: '5%',
						// containLabel: true
					},
					xAxis: [{
						type: 'category',
						axisLine: {
							show: true
						},
						splitArea: {
							// show: true,
							color: '#f00',
							lineStyle: {
								color: '#f00'
							},
						},
						axisLabel: {
							show: false,
							margin: 20,
							textStyle: {
								color: 'white',

							},
						},
						splitLine: {
							show: false
						},
						boundaryGap: false,
						data: data.xAxis,
					}],
					yAxis: [{
						type: 'value',
						min: 0,
						// max: 140,
						splitNumber: 4,
						splitLine: {
							show: true,
							lineStyle: {
								color: 'white'
							}
						},
						axisLine: {
							show: false,
						},
						axisLabel: {
							show: false,
							margin: 20,
							textStyle: {
								color: 'white',

							},
						},
						axisTick: {
							show: false,
						},
					}],
					series: series1
				};

				myChart.setOption(options)

			},
			getUnitInfo(data) {
				console.log('qsar', data)
				let itemData = [4000, 700, 3600, 3900, 1800, 4200, 700, 3600]
				var myChart = echarts.init(document.getElementById(data.name));
				var legendData = ['评分:']; //图例
				var indicator = [{
						text: '非常满意',
						max: 6000,
					},
					{
						text: '非常不满意',
						max: 5000
					},
					{
						text: '不满意',
						max: 5000
					},
					{
						text: '一般',
						max: 5000,
					},
					{
						text: '满意',
						max: 5000
					}
				];
				if (data.name == 'unitInfo1') {
					legendData = ['报警数:']; //图例
					indicator = data.data.indicator
					itemData = data.data.itemData
				}
				var dataArr = [{
					value: itemData,
					name: legendData[0],
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#e8ff00',
							},
						},
					},
					areaStyle: {
						normal: { // 单项区域填充样式
							color: {
								type: 'linear',
								x: 1, //右
								y: 0, //下
								x2: 1, //左
								y2: 1, //上
								colorStops: [{
									offset: 0.1,
									color: '#e8ff00'
								}, {
									offset: 1,
									color: '#e8ff00'
								}],
								globalCoord: false
							},
							opacity: 0.4 // 区域透明度
						}
					}
				}];
				var colorArr = ['white', 'white']; //颜色
				var options = {
					color: colorArr,
					tooltip: {
						show: true,
						trigger: "item"
					},
					radar: {
						radius: "85%",
						center: ["50%", "50%"],
						name: {
							textStyle: {
								color: '#fff',
								fontSize: 16,
								padding: [-20, -20]
							},
						},
						indicator: indicator,
						splitArea: { // 坐标轴在 grid 区域中的分隔区域，默认不显示。
							show: true,
							areaStyle: { // 分隔区域的样式设置。
								color: ['rgba(255,255,255,0)',
									'rgba(255,255,255,0)'
								], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
							}
						},
						axisLine: { //指向外圈文本的分隔线样式
							lineStyle: {
								color: '#153269'
							}
						},
						splitLine: {
							lineStyle: {
								color: '#2b75d2', // 分隔线颜色
								width: 2, // 分隔线线宽
							}
						},
					},
					series: [{
						type: 'radar',
						symbolSize: 8,
						symbol: 'circle',
						data: dataArr
					}]
				};
				myChart.setOption(options)

			},
			getOne(data) {
				var myChart = echarts.init(document.getElementById(data.name));
				var scaleData = data.data
				if (scaleData == null) {
					scaleData = [{
							'name': 'Ⅰ级风险',
							'value': 300
						},
						{
							'name': 'Ⅱ级风险',
							'value': 200
						},
						{
							'name': 'Ⅲ级风险',
							'value': 100
						},
						{
							'name': 'Ⅳ级风险',
							'value': 100
						}
					];
				}
				var placeHolderStyle = {
					normal: {
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						color: 'rgba(0, 0, 0, 0)',
						borderColor: 'rgba(0, 0, 0, 0)',
						borderWidth: 0
					}
				};
				var data = [];
				var color1 = ['#068af0', '#719d98', '#c5ff5f', '#5fffc1',
					'#2a4e68', '#77a88f'
				];

				for (var i = 0; i < scaleData.length; i++) {
					data.push({
						value: 2,
						name: '',
						itemStyle: placeHolderStyle
					}, {
						value: scaleData[i].value,
						name: scaleData[i].name,
						itemStyle: {
							normal: {
								borderWidth: 4,
								borderColor: color1[i],
								color: color1[i],
								opacity: 0.75,
							}
						}
					});
				}
				var seriesObj = [{
					type: 'pie',
					clockWise: false,
					radius: ['40%', '90%'],
					center: ['30%', '53%'],
					hoverAnimation: false,
					itemStyle: {
						normal: {
							label: {
								show: false,
							},
							labelLine: {
								show: false,
							}
						}
					},
					data: data
				}];
				let options = {
					tooltip: {
						trigger: 'item',
						formatter: function(parms) {
							if (parms.data.name != '') {
								var str =
									parms.marker + "" + parms.data.name + "</br>" +
									"数量：" + (parms.data.value/10) + "</br>" +
									"占比：" + parms.percent + "%";
								return str;
							}

						}
					},
					legend: {
						selectedMode: false,
						type: "scroll",
						orient: 'vertical',
						right: '15%',
						align: 'left',
						top: 'middle',
						textStyle: {
							color: 'white'
						}
					},
					toolbox: {
						show: false
					},
					series: seriesObj
				}
				myChart.setOption(options)
			},
			getNd(data) {
				let color = [
					['#08a7ab', '#a8d3ff'],
					['#506fee', '#9dcfff'],
					['#976cff', '#9ad6f7']
				]
				let arr = ["已维保", "未维保", "超期维保"]
				let series = []
				arr.forEach((res, ind) => {
					series.push({
						name: res,
						type: "bar",
						barWidth: 10,
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: color[ind][0]
									},
									{
										offset: 1,
										color: color[ind][1]
									}
								]),
								borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: color[ind][0]
									},
									{
										offset: 1,
										color: color[ind][1]
									}
								]),
								borderWidth: 2
							}
						},
						data: data.count[ind]
					})
					series.push({
						// name: res,
						type: "line",
						yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
						smooth: true, //平滑曲线显示
						showAllSymbol: true, //显示所有图形。
						symbol: "circle", //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: color[ind][0],
							borderColor: color[ind][0],
							width: 2,
							shadowColor: color[ind][0],
							shadowBlur: 4
						},
						lineStyle: {
							color: color[ind][0],
							width: 2,
							shadowColor: color[ind][0],
							shadowBlur: 4
						},
						areaStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: "rgba(61,126,235, 0.5)"
								},
								{
									offset: 1,
									color: "rgba(61,126,235, 0)"
								}
							])
						},
						data: data.count[ind]
					})
				})

				var myChart = echarts.init(document.getElementById('leftTwo'));
				var options = {
					grid: {
						icon: 'rect',
						top: '15%',
						left: '10%',
						right: '5%',
						bottom: '15%',
						// containLabel: true
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
							label: {
								show: true
							},
						},
						formatter: function(params) {
							var relVal = params[0].name;
							for (var i = 0, l = params.length; i < l; i++) {
								if (params[i].seriesType != 'line') {
									relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i]
										.value.toLocaleString() + "次";
								}
							}
							return relVal;
						}
					},
					legend: {
						data: arr,
						// data: ["已维保", "未维保", "超期维保"],
						top: "2%",
						left: '10%',
						textStyle: {
							color: "white",
							fontSize: 16
						}
					},
					xAxis: {
						data: data.xAxis,
						axisLine: {
							show: true, //隐藏X轴轴线
							lineStyle: {
								color: '#26D9FF',
								width: 2
							}
						},
						axisTick: {
							show: true //隐藏X轴刻度
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "white", //X轴文字颜色
								fontSize: 16
							}
						},
						splitArea: {
							show: true,
							areaStyle: {
								color: ["rgba(250,250,250,0.1)", "rgba(250,250,250,0)"]
							}
						}
					},
					yAxis: [{
							type: "value",
							/*name: "亿元",*/
							nameTextStyle: {
								color: "white",
								fontSize: 16
							},
							splitLine: {
								show: false
							},
							axisTick: {
								show: true
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: '#26D9FF',
									width: 2
								}
							},
							axisLabel: {
								show: true,
								textStyle: {
									color: "white",
									fontSize: 16
								}
							},

						},
						{
							type: "value",
							/*name: "同比",*/
							nameTextStyle: {
								color: "#ebf8ac",
								fontSize: 16
							},
							position: "right",
							splitLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							axisLine: {
								show: false
							},
							axisLabel: {
								show: false
							}
						}
					],
					series: series
				}
				myChart.setOption(options)
			},
			back() {
				window.history.go(-1)
			},
		},
	}
</script>

<style>
	@font-face {
		font-family: 'led regular';
		src: url('../../font/Digital-7.ttf');
	}

	.amap-info-content {
		padding: 0px !important;
	}
</style>

<style lang="scss" scoped>
	.lodinBack {
		width: 100%;
		height: 100vh;
		position: absolute;
		z-index: 99999;
	}

	.bgcolo {
		width: 100%;
		height: 98vh;
		// opacity: 0.5;
		position: absolute;
		// z-index: 9999999;
		// background-color: black;
	}

	.tbdod {
		width: 100%;
		height: 100vh;
		background: url(../../assets/lodinbg.png) no-repeat;
		background-size: 100% 100%;
	}

	.wbScoll {
		color: white;
		width: 100%;
		height: 80%;
		margin-top: 3%;
		overflow-y: scroll;

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			border-bottom: 1px solid #089ff5;
		}

		span {
			color: #089ff5;
			font-weight: bold;
		}
	}

	// 过度动画
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 1s;
	}

	.fade-enter,
	.fade-leave-to

	/* .fade-leave-active below version 2.1.8 */
		{
		opacity: 0;
	}

	//
	.titBut {
		position: absolute;
		right: 20px;
	}

	.topBgBoxCent {
		width: 26%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.topBgBox1 {
		width: 37%;
		height: 100%;
		background: url(../../assets/topTitright.gif) no-repeat;
		background-position: center left;
		background-size: 100% 100%;
	}

	.topBgBox2 {
		width: 37%;
		height: 100%;
		background: url(../../assets/topTitrleft.gif) no-repeat;
		background-position: center right;
		background-size: 100% 100%;
	}

	.bgright,
	.bgbom,
	.bgTop,
	.bgleft,
	.bgImg {
		position: absolute;
		z-index: 500;
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.topButSp1 {
		font-family: 'led regular';
		color: #20dbfd;
		font-size: 2vh;
	}

	.topButSp2 {
		font-size: 16px;
		margin-bottom: 1%;
	}

	.topBut {
		width: 30%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-image: url("../../assets/titlun.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		color: white;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.binA,
	#highSos,
	#Annual,
	#leftTwo,
	#leftone1,
	#leftone2,
	#leftone3,
	#unitInfo1,
	#unitInfo2 {
		// z-index: 99999;
		width: 100%;
		height: 80%;
	}

	.zBomBox {
		width: 36%;
		height: 15vh;
		padding: 0.5%;
		margin: 0 0 0 32%;
		bottom: 2vh;
		position: absolute;
		z-index: 501;
		display: flex;
		align-items: center;
		justify-content: center;

		.zbomBut {
			width: 150px;
			height: 50px;
			color: white;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url("../../assets/goBut.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin: 0 30px;
		}
	}

	.zTopBox {
		width: 36%;
		height: 11%;
		padding: 0.5%;
		margin: 12vh 0 0 32%;
		position: absolute;
		z-index: 998;
	}

	.wdrop {
		width: 100%;
		height: 90%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.boxImg {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		background: url(../../assets/msgTit.png) no-repeat;
		background-position: bottom center;
		background-size: contain;
	}

	.boxImg span {
		color: white;
		font-size: 18px;
		margin-left: 10px;
	}

	.rightBox {
		width: 30%;
		top: 11vh;
		right: 0.5%;
		height: 86vh;
		position: absolute;
		z-index: 998;
	}

	.leftBox {
		width: 30%;
		top: 11vh;
		left: 0.5%;
		height: 86vh;
		position: absolute;
		z-index: 998;
	}

	.leftBox-a {
		width: 92%;
		height: 30%;
		padding: 1% 4% 4% 4%;
		background: url(../../assets/tlbg.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
	}

	#allmap {
		width: 40%;
		height: 85vh;
		position: absolute;
		top: 11vh;
		left: 30%;
		opacity: 0.92;
		// margin-top: 15vh;
	}

	.topBg {
		height: 100px;
		position: absolute;
		z-index: 998;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			font-size: 1.5vw;
			color: white;
		}
	}
	.sosCallBox {
  width: 290px;
  min-height: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 10px 0;
  background-color: #f0ffff;
}

.sosCallBox span {
  font-size: 18px;
  font-weight: bold;
}

.sosMsg {
  margin-top: 10px;
  // display: flex;
  width: 100%;
  max-height: 61px;
  // line-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.sosCall {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  /* width: 100%; */
  /* overflow: auto; */
  display: flex;
  flex-wrap: wrap;
}
</style>
