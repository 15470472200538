<template>
  <div
    :class="className"
    :style="{width:width, height:height}"
  />
</template>

<script>
import echarts from 'echarts'
// import resize from '@/utils/mixins/resize'

export default {
  // mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '50vh',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    sosLineData: {
      type: Object,
      default: () => {
        return {
          x: [],
          y: [],
          z: [],
          aSpeed: [],
          oldV: [],
          upstatus: [],
          outSpeed: [],
          downstatus: [],
          floor: [],
          vNow: [],
          sumaSpeed: [],
          heightChange: [],
          time: [],
        }
      },
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  // 监听data里面对应的
  watch: {
    sosLineData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions({
      x,
      y,
      z,
      aSpeed,
      oldV,
      upstatus,
      outSpeed,
      downstatus,
      floor,
      vNow,
      sumaSpeed,
      heightChange,
      time,
    } = {}) {
      const colors = [
        '#5793f3',
        '#d14a61',
        '#675bba',
        'green',
        'blue',
        '#673AB7',
        '#FF9800',
      ]
      const options = {
        color: colors,
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross',
          },
        },
        legend: {
          data: [
            'x',
            'y',
            'z',
            'aSpeed',
            'oldV',
            'upstatus',
            'outSpeed',
            'downstatus',
            'floor',
            'vNow',
            'sumSpeed',
            'heightChange',
          ],
          selected: {
            x: false,
            y: false,
            z: false,
            aSpeed: true,
            oldV: false,
            upstatus: false,
            outSpeed: false,
            downstatus: false,
            floor: false,
            vNow: false,
            sumaSpeed: false,
            heightChange: false,
          },
        },
        grid: {
          top: 70,
          bottom: 50,
          right: 10,
        },
        dataZoom: [
          // 下层拖拽缩放
          {
            type: 'slider',
            xAxisIndex: 0,
            startValue: 0,
            endValue: 100000, // 只显示7个数据
            bottom: 0,
            height: 15,
          },
          // 图表内也可缩放
          {
            type: 'inside',
          },
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colors[1],
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.seriesData.length
                    ? params.seriesData[0].seriesName +
                        '轴' +
                        '：' +
                        params.seriesData[0].data
                    : ''
                },
              },
            },
            data: time,
          },
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colors[0],
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.seriesData.length
                    ? params.seriesData[0].seriesName +
                        '轴' +
                        '：' +
                        params.seriesData[0].data
                    : ''
                },
              },
            },
            data: time,
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: 'dataMin', // 最小值
            scale: true /* 按比例显示*/,
          },
        ],
        series: [
          {
            name: 'x',
            type: 'line',
            xAxisIndex: 1,
            smooth: true,
            data: x,
          },
          {
            name: 'y',
            type: 'line',
            smooth: true,
            data: y,
          },
          {
            name: 'z',
            type: 'line',
            smooth: true,
            data: z,
          },
          {
            name: 'aSpeed',
            type: 'line',
            smooth: true,
            data: aSpeed,
          },
          {
            name: 'oldV',
            type: 'line',
            smooth: true,
            data: oldV,
          },
          {
            name: 'upstatus',
            type: 'line',
            smooth: true,
            data: upstatus,
          },
          {
            name: 'outSpeed',
            type: 'line',
            smooth: true,
            data: outSpeed,
          },
          {
            name: 'downstatus',
            type: 'line',
            smooth: true,
            data: downstatus,
          },
          {
            name: 'floor',
            type: 'line',
            smooth: true,
            data: floor,
          },
          {
            name: 'vNow',
            type: 'line',
            smooth: true,
            data: vNow,
          },
          {
            name: 'sumaSpeed',
            type: 'line',
            smooth: true,
            data: sumaSpeed,
          },
          {
            name: 'heightChange',
            type: 'line',
            smooth: true,
            data: heightChange,
          },
        ],
      }
      this.chart.setOption(options)
    },
  },
}
</script>
