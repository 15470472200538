<template>
  <div style="padding-top: 10px;">
    <div style="display: flex;background: #1282a5;border: 1.5px solid #FFF;border-radius: 3px;padding: 10px;">
      <div style="height: 389px;display: flex;align-items: center;">
        <div v-show="percentage != 100"
          style="height: 381px; display: flex; justify-content: center; align-items: center;background: #000; width: 505px;margin-right: 27px;">
          <div style="position: relative;">
            <span class="span-width">{{percentage}}%</span>
            <img :src="require(`@/assets/img/${percentage}.png`)" style="width: 75px; height: 75px;">
          </div>
        </div>
        <div v-show="percentage == 100" style="width: 95%;">
          <div>
            <video style="width: 100%;height: 395px;margin-top: -12px;" controls autoplay :muted='isMuted'
              id="onPlayJkSos" />
            <template v-for="item in meData">
              <video v-show="false" style="width: 100px;height: 100px;" muted controls autoplay :id="item.session_id"
                :key="item.session_id" />
            </template>
          </div>
        </div>
      </div>
      <div style="padding: 0px 10px;background: #025682;width: 383px;height: 381px;margin-left: -15px;margin-top: 4px;">
        <p>
          报警类型：
          <el-button type="danger" size="mini" style="cursor: auto;"
            v-if="typeDate.find(item=>item.value==dioDetailsItem.type)">
            {{typeDate.find(item=>item.value==dioDetailsItem.type).label}}
          </el-button>
        </p>
        <p :title="dioDetailsItem.name" @click="Copy(dioDetailsItem.name,1)"
          style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow-y: hidden;height: 40px;cursor: pointer;">
          电梯名称：{{dioDetailsItem.name}}
        </p>
        <p>报警时间：{{dioDetailsItem.time}}</p>
        <p>报警楼层：{{dioDetailsItem.floor}}</p>
        <p>摄像头ID：{{dioDetailsItem.code}}</p>
        <p :title="dioDetailsItem.plotName" @click="Copy(dioDetailsItem.plotName,1)"
          style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow-y: hidden;height: 22px;cursor: pointer;">
          小区名称：{{dioDetailsItem.plotName}}
        </p>
        <div style="display: flex;margin-top: -15px;align-items: flex-start;height: 80px;">
          <p style="word-break: keep-all;">维保人员：</p>
          <div v-if="dioDetailsItem.maintainUser.split(';').length > 0">
            <!-- {{dioDetailsItem.maintainUser.split(';')}} -->
            <div v-for="(item,index) in dioDetailsItem.maintainUser.split(';')" style="width: 200px;">
              <p v-if="index < 2">
                {{item}}
                <i class="el-icon-document-copy copyInfo" @click="Copy(item,2)"></i>
              </p>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-top: -15px;align-items: flex-start;height: 60px;">
          <p style="word-break: keep-all;">物业人员：</p>
          <div v-if="dioDetailsItem.propertyUser.split(';').length > 0">
            <div v-for="(item,index) in dioDetailsItem.propertyUser.split(';')" style="width: 200px;">
              <p v-if="index < 2">
                {{item}}
                <i class="el-icon-document-copy copyInfo" @click="Copy(item,2)"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="conBox">
      <!-- <el-button :disabled='isDial' @click="dialZlv" title="拨打" type="success" icon="el-icon-microphone">拨打</el-button> -->
      <el-button size="mini" @click="dropped" type="danger">
        <i class="el-icon-turn-off-microphone"></i>
        <p style="margin: 10px 0 0 0;">挂断</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="primary" @click="sosClick(1)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 98">
        <span>人为触发(无故障)</span>
        <p style="margin: 10px 0 0 0;">确认关闭</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="info" @click="sosClick(2)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 98&&dioDetailsItem.type != 21">
        <span>电梯故障(无困人)</span>
        <p style="margin: 10px 0 0 0;">派发维修工单</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="warning" @click="sosClick(3)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 109&& dioDetailsItem.type != 21 &&dioDetailsItem.type != 19 &&dioDetailsItem.type != 20 && dioDetailsItem.type != 15&&dioDetailsItem.type != 13&& dioDetailsItem.type != 96">
        <span>困人</span>
        <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
      </el-button>
      <el-button size="mini" class="errorSos" @click="sosClick(5)" :loading="btnLoading" :disabled="sosStatus"
        v-if="dioDetailsItem.type != 98">
        <span>误报</span>
        <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
      </el-button>
      <!-- <el-button  @click="dialZlv" title="关闭" type="info" icon="el-icon-microphone">关闭</el-button> -->
    </div>
  </div>
</template>

<script>
  let one = true
  export default {
    props: ['jkData', 'sosId', 'alarmTime', 'percentage', 'videoStatusId', 'ids', 'dioDetailsItem'],
    data() {
      return {
        timer: null,
        timeCount: null,
        // 监控
        player: null,
        loading: true,
        timeWebRtc: null,
        // 通话
        isDial: false,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isMuted: true,
        meData: [],
        meObj: null,
        sosStatus: false,
        playTime: 0, // 通话时间
        playWebrtc: '',
        btnLoading: false,
        meplayer: null,
        timers: null,
        PUrl: [],
        typeDate: [],
        playNum: 0
      }
    },
    created() {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item) => {
              if (this.adminInfo.userName != 'admin') {
                return item.value != '21' && item.value != '35'
              } else {
                return item
              }
            })
            this.typeDate = Arr
          }
        })
    },
    mounted() {
      this.PUrl = []
      // if (this.dioDetailsItem.type == 98) {
      //   setTimeout(() => {
      //     this.checkList()
      //     this.vidieoList(1)
      //   }, 1000)
      // } else {
      let start = 30
      this.timeCount = setInterval(() => {
        start--
        if (start > -1) {
          if (this.timer == '失败' || this.timer == null) {
            if (start < 1) {
              this.getTimes(2)
            }
          } else {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        } else {
          if (this.timer == '成功') {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        }
      }, 1000)
      setTimeout(() => {
        this.init()
        this.dialZlv()
      }, 1000)


      // }
    },
    beforeDestroy() {
      // if (this.dioDetailsItem.type != 98) {
      let agentData = {
        act: 'ma_stop_webrtc_call',
        sos_room_id: this.jkData.device_id,
      }
      this.socketApi.sendSock(agentData, (e) => {})
      one = true
      if (this.meplayer) {
        this.meplayer.close()
        this.meplayer = null
        var local = document.getElementById(this.meObj)
        if (local) {
          local.srcObject = null
          local.load()
        }
      }
      this.stop()
      // } else {
      //   if (this.meplayer) {
      //     this.meplayer.close()
      //     this.meplayer = null
      //     var local = document.getElementById(this.meObj)
      //     if (local) {
      //       local.srcObject = null
      //       local.load()
      //     }
      //   }
      //   this.PUrl = []
      //   this.stop()
      //   this.dropped()
      // }
    },

    methods: {
      useCopyToClipboard(val) {
        let isSuccessRef = false;
        //创建input标签
        const input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.setAttribute('readonly', 'readonly')
        input.setAttribute('value', val)
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand("copy");
        if (document.execCommand("copy")) {
          isSuccessRef = true;
        } else {
          isSuccessRef = false;
        }
        //移除input标签
        document.body.removeChild(input);

        return {
          isSuccessRef
        };
      },
      Copy(str, type) { //复制
        console.log(111)
        let data = ''
        if (type == 1) {
          data = str
        } else {
          const one = str.split('(')[1]
          data = one.split(')')[0]
        }
        let {
          isSuccessRef
        } = this.useCopyToClipboard(data)
        if (isSuccessRef) {
          this.$message({
            type: 'success',
            message: '复制成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: '复制失败'
          })
        }
      },
      // 详情操作按钮
      sosClick(type) {
        if (type != 4) {
          this.btnLoading = true
          this.sosStatus = true
        }
        this.$http
          .post('/api/ele/app/sos/submit', {
            id: this.sosId,
            submitType: type,
            sosTime: this.alarmTime
          })
          .then((res) => {
            if (type != 4) {
              if (res.data.success) {
                if (type == 5) {
                  this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                    type: 'warning',
                  }).then(() => {
                    this.$http
                      .post("/api/ele/web/sos/removeSosInfo", {
                        id: this.sosId,
                        sosTime: this.alarmTime,
                      }).then((res) => {
                        if (res.data.code == 200) {
                          this.$message.success(res.data.msg)
                        } else {
                          this.$message.error(res.data.msg)
                        }
                      })
                  }).catch(() => {})
                }
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            }
            this.btnLoading = false
          })
      },
      // 拨打
      meInit(data) {
        if (this.meplayer) {
          this.meplayer.close()
          this.meplayer = null
        }
        var local = document.getElementById(data.session_id)
        if (local) {
          local.load()
          local.srcObject = null
        }
        const jswebrtc = document.getElementById(data.session_id)
        const that = this
        that.meplayer = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: data.url, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: true,
          videoEnable: false,
          recvOnly: data.recvType, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480,
          },
        })

        if (one) {
          one = false
          that.sosClick(4)
        }
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
          function(e) {
            // ICE 协商出错
            console.log('ICE 协商出错')
          }
        )
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
          function(e) {
            //获取到了远端流，可以播放
            that.timer = '成功'
            console.log('播放成功', e.streams)
            that.loading = false

            // if (that.dioDetailsItem.type == 98) {
            //   that.timers = setInterval(() => {
            //     that.vidieoList()
            //   }, 3000)
            // }
          }
        )
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
          function(e) {
            that.timer = '失败'
            // offer anwser 交换失败
            console.log('offer anwser 交换失败', e)
          }
        )
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,
          function(s) {
            // 获取到了本地流
            jswebrtc.srcObject = s
            jswebrtc.muted = true
            that.timer = '成功'
            console.log('获取本地流成功')
            console.log(that.dioDetailsItem.type, 'that.dioDetailsItem.type');
            // if (that.dioDetailsItem.type == 98) {
            //   that.timers = setInterval(() => {
            //     that.vidieoList()
            //   }, 3000)
            // }
            // if (that.dioDetailsItem.type != 98) {
            //   that.playWebrtc = setInterval(() => {
            //     that.playTime = that.playTime + 1
            //     if (that.playTime > 600) {
            //       that.dropped()
            //     }
            //   }, 1000)
            // }
          }
        )
        that.meplayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
          // 获取本地流失败
          that.timer = '失败'
          console.log('获取本地流失败2')
        })

      },
      // checkList() { //呼救推流
      //   const me_id = JSON.parse(sessionStorage.getItem('me_id'))
      //   console.log(me_id, 'me_id')
      //   var that = this
      //   this.$http.get(`/api/ele/web/pushWechatStartCall?sosId=${this.sosId}&sessionId=${me_id.token}`).then((
      //     res) => {
      //     console.log(res, '列表')
      //     if (res.data.code == 200) {
      //       let obj = {
      //         url: res.data.data.push,
      //         recvType: false,
      //         session_id: 'wxShowVideo',
      //       }
      //       // this.meData.push(obj)

      //       setTimeout(() => {
      //         that.meInit(obj)
      //       }, 1000)
      //     } else {
      //       this.$message(res.data.msg)
      //       return
      //     }
      //   })
      // },
      // vidieoList(type) { //呼救拉流
      //   console.log('vidieoList');
      //   const me_id = JSON.parse(sessionStorage.getItem('me_id'))
      //   // var that = this
      //   this.$http.get(`/api/ele/web/pushWechatCallList?sosId=${this.sosId}`).then((res) => {
      //     console.log(res, '列表')
      //     if (res.data.code == 200) {
      //       console.log(res.data)
      //       const reg = /^\d+$/
      //       let Arr = []
      //       this.PUrl = []
      //       console.log(type, 'type1')
      //       if (type == 1) {
      //         console.log(type, 'type2')
      //         if (!res.data.data.find(item => reg.test(item.sessionId))) {
      //           this.playNum++
      //           setTimeout(() => {
      //             this.vidieoList(1)
      //           }, 500)
      //           // return
      //         }
      //         let playObj = res.data.data.find(item => reg.test(item.sessionId))
      //         let obj = {
      //           url: playObj.play,
      //           recvType: true,
      //           session_id: playObj.sessionId,
      //         }
      //         // if (reg.test(playObj.sessionId)) {
      //         obj.url = playObj.play
      //         obj.recvType = true
      //         this.PUrl.push(obj)
      //         console.log(this.PUrl, 'this.PUrl')
      //         this.meObj = playObj.sessionId
      //         this.check()
      //         // }
      //       } else {
      //         // 轮训
      //         if (!res.data.data.find(item => reg.test(item.sessionId))) {
      //           clearInterval(this.playWebrtc)
      //           clearInterval(this.timers)
      //           this.$emit('bclose')
      //           this.playTime = 0
      //           this.isMuted = true
      //           this.isDial = false
      //           this.PUrl = []
      //           if (this.meplayer) {
      //             this.meplayer.close()
      //             this.meplayer = null
      //             var local = document.getElementById(this.meObj)
      //             if (local) {
      //               local.srcObject = null
      //               local.load()
      //             }
      //           }
      //         }
      //       }

      //       // res.data.data.forEach((qs, ind) => {
      //       //   console.log(qs, ind, 666)
      //       //   if (reg.test(qs.sessionId)) {
      //       //     Arr.push(false)
      //       //   } else {
      //       //     Arr.push(true)
      //       //   }
      //       //   // /^\d+$/.test(str);
      //       //   console.log(Arr, 'Arr')
      //       //   console.log(Arr.find((item) => item == false), '是否挂断')
      //       //   if ((Arr && Arr.find((item) => item == false)) || Arr.find((item) => item == false) == undefined) {
      //       //     // clearInterval(this.playWebrtc)
      //       //     // clearInterval(this.timers)
      //       //     // this.$emit('bclose')
      //       //     // this.playTime = 0
      //       //     // this.isMuted = true
      //       //     // this.isDial = false
      //       //     // this.PUrl = []
      //       //     // if (this.meplayer) {
      //       //     //   this.meplayer.close()
      //       //     //   this.meplayer = null
      //       //     //   var local = document.getElementById(this.meObj)
      //       //     //   if (local) {
      //       //     //     local.srcObject = null
      //       //     //     local.load()
      //       //     //   }
      //       //     // }
      //       //   } else {
      //       //     let obj = {
      //       //       url: qs.play,
      //       //       recvType: true,
      //       //       session_id: qs.sessionId,
      //       //     }

      //       //     if (reg.test(qs.sessionId)) {
      //       //       obj.url = qs.play
      //       //       obj.recvType = true
      //       //       that.PUrl.push(obj)
      //       //       that.meObj = qs.sessionId
      //       //       if (type == 1) {
      //       //         that.check()
      //       //       }
      //       //     }
      //       //     // that.check()
      //       //     // console.log(me_id.sessionId, qs.sessionId, 666)
      //       //   }
      //       //   // // if (ind != 0) {
      //       //   // //recvType 拉流为true 推流为false
      //       //   // let obj = {
      //       //   //   url: qs.play,
      //       //   //   recvType: true,
      //       //   //   session_id: qs.sessionId,
      //       //   // }
      //       //   // // console.log(me_id.sessionId, qs.sessionId, 666)

      //       //   // if (reg.test(qs.sessionId)) {
      //       //   //   obj.url = qs.play
      //       //   //   obj.recvType = true
      //       //   //   that.PUrl.push(obj)
      //       //   //   that.meObj = qs.sessionId
      //       //   // } else {

      //       //   // }

      //       //   // this.meData.push(obj)
      //       //   // }
      //       // })
      //       // // console.log(that.meData, 'medate')
      //       // // that.meData.forEach((qs) => {
      //       // //   that.meInit(qs)
      //       // // })
      //     } else {
      //       setTimeout(() => {
      //         this.vidieoList(1)
      //       }, 1000)
      //       // this.$message(res.data.msg)
      //       // return
      //     }
      //   })
      // },
      check() {
        var that = this
        console.log(that.PUrl, 'check')
        const jswebrtc = document.getElementById('onPlayJkSos')
        let playUrl = that.PUrl[0].url
        this.player = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: playUrl, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: false,
          videoEnable: false,
          recvOnly: true, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480,
          },
        })
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
          function(e) {
            // ICE 协商出错
            console.log('ICE 协商出错')
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
          function(e) {
            //获取到了远端流，可以播放
            that.timer = '成功'
            console.log('播放成功', e.streams)
            clearInterval(that.timeWebRtc)
            that.$emit('changePercentage', 100)
            that.loading = false
            if (that.dioDetailsItem.type == 98) {
              // that.checkList()
            }
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
          function(e) {
            that.timer = '失败'
            // offer anwser 交换失败
            console.log('offer anwser 交换失败', e)
          }
        )
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) {
          // 获取到了本地流
          jswebrtc.srcObject = s
          jswebrtc.muted = false
          this.timer = '成功'
          console.log('获取本地流成功1')
          if (this.dioDetailsItem.type == 98) {
            // this.checkList()
          }
        })
        this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
          // 获取本地流失败
          that.timer = '失败'
          console.log('获取本地流失败1')
        })
      },
      dialZlv() {
        let agentData = {
          type: 2,
          act: 'ma_webrtc_call',
          sos_room_id: this.jkData.device_id,
          token: sessionStorage.getItem('AdminToken'),
        }
        if (sessionStorage.getItem('me_id')) {
          let se_id = JSON.parse(sessionStorage.getItem('me_id')).sessionId
          this.socketApi.sendSock(agentData, (e) => {
            // one 只执行一次
            if (e.cmd == 'ma_webrtc_call' && e.room_info != undefined && one) {
              this.isMuted = false
              e.room_info.members.forEach((qs, ind) => {
                if (ind != 0) {
                  //recvType 拉流为true 推流为false
                  let obj = {
                    url: qs.play,
                    recvType: true,
                    session_id: qs.session_id,
                  }
                  if (qs.session_id == se_id) {
                    obj.url = qs.push
                    obj.recvType = false
                    this.meData.push(obj)
                    this.meObj = qs.session_id
                  }
                  // this.meData.push(obj)
                }
              })
              this.isDial = true
              setTimeout(() => {
                this.meData.forEach((qs) => {
                  this.meInit(qs)
                })
              }, 1000)
            } else {
              clearInterval(this.timeWebRtc)
            }
          })

        } else {
          this.$message.error('您现在的网络情况不好，请刷新页面再尝试')
          return
        }
      },
      // 挂断
      dropped() {
        const me_id = JSON.parse(sessionStorage.getItem('me_id'))
        // if (this.dioDetailsItem.type != 98) {
        clearInterval(this.playWebrtc)
        this.playTime = 0
        this.isMuted = true
        this.isDial = false

        let agentData = {
          act: 'ma_stop_webrtc_call',
          sos_room_id: this.jkData.device_id,
        }
        this.socketApi.sendSock(agentData, (e) => {})

        this.$emit('bclose')
        one = true
        if (this.meplayer) {
          this.meplayer.close()
          this.meplayer = null
          var local = document.getElementById(this.meObj)
          if (local) {
            local.srcObject = null
            local.load()
          }
        }
        // } else {
        //   this.$http.get(`/api/ele/web/pushWechatStopCall?sosId=${this.sosId}&sessionId=${me_id.token}`).then((
        //     res) => {
        //     if (res.data.code == 200) {
        //       clearInterval(this.playWebrtc)
        //       clearInterval(this.timers)
        //       this.playTime = 0
        //       this.isMuted = true
        //       this.isDial = false
        //       this.$emit('bclose')
        //       if (this.meplayer) {
        //         this.meplayer.close()
        //         this.meplayer = null
        //         var local = document.getElementById(this.meObj)
        //         if (local) {
        //           local.srcObject = null
        //           local.load()
        //         }
        //       }
        //     } else {
        //       this.$message(res.data.msg)
        //     }
        //   })
        // }
      },
      // 阻梯
      ladder() {},
      // 重连
      opJk() {
        this.stop()
        if (this.dioDetailsItem.type != 98) {
          // this.stop()
          let agentData = {
            act: 'ca_start_rtsp',
            device_id: this.jkData.device_id,
          }
          this.socketApi.sendSock(agentData, (e) => {
            if (e.status) {
              if (e.cmd == 'ca_start_rtsp') {
                if (e.hb) {
                  this.$emit('changePercentage', 25)
                }
                if (e.cmd == 'ca_start_rtsp') {
                  this.$emit('changePercentage', 50)
                  this.getStreamStatus()
                }
                // this.init()
              }
            } else {
              this.$message.error(e.msg)
            }
          })
        } else {
          // this.timers = setInterval(() => {
          this.vidieoList()
          // }, 3000)
        }
      },

      getStreamStatus() {
        this.$http
          .get(`api/ele/web/getStreamStatus?id=${this.jkData.device_id}`)
          .then((res) => {
            if (res.data.success) {
              this.$emit('changePercentage', res.data.data ? 75 : 50)
              this.init()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      //关闭监控
      stop() {
        clearInterval(this.timeWebRtc)
        if (this.player) {
          this.player.close()
          this.player = null
          var local = document.getElementById('onPlayJkSos')
          if (local) {
            local.srcObject = null
            local.load()
          }
        }
      },
      //更新视频时间
      getTimes(type) {
        console.log(this.ids,'this.ids');
        console.log(this.videoStatusId,'this.videoStatusId');
        const eleId = this.videoStatusId ?? this.ids
        const time = new Date(new Date()).getTime()
        // if (this.videoStatusId) {
          this.$http.post(
            `/api/ele/web/eleVideo/updateElevatorVideoStatus?id=${eleId}&videoTime=${time}&videoStatus=${type}`
          ).then((res) => {
            console.log(res, 'res')
            this.$emit('update', time, type)
          })
        // } else {
        //   this.$http.post(
        //     `/api/ele/web/eleVideo/updateElevatorVideoStatus?elevatorId=${this.ids}&videoTime=${time}&videoStatus=${type}`
        //   ).then((res) => {
        //     this.$emit('update', time, type)
        //     console.log(res, 'res')
        //   })
        // }
      },
      init() {
        const that = this
        const jswebrtc = document.getElementById('onPlayJkSos')
        let openUrl = that.jkData.play_url
        let playUrl = openUrl[openUrl.length - 2]
        this.player = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: playUrl, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: false,
          videoEnable: false,
          recvOnly: true, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480,
          },
        })
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
          function(e) {
            // ICE 协商出错
            console.log('ICE 协商出错')
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
          function(e) {
            //获取到了远端流，可以播放
            that.timer = '成功'
            console.log('播放成功', e.streams)
            clearInterval(that.timeWebRtc)
            that.$emit('changePercentage', 100)
            that.loading = false
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
          function(e) {
            that.timer = '失败'
            // offer anwser 交换失败
            console.log('offer anwser 交换失败', e)
          }
        )
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) {
          // 获取到了本地流
          jswebrtc.srcObject = s
          jswebrtc.muted = false
          that.timer = '成功'
          console.log('获取本地流成功1')
        })
        this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
          // 获取本地流失败
          that.timer = '失败'
          console.log('获取本地流失败1')
        })
      },
    },
  }
</script>

<style scoped>
  .copyInfo {
    margin-left: 3px;
    font-size: 18px;
    cursor: pointer;
  }

  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .conBox {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    /* width: 6%; */
    height: 100%;
    margin-top: 15px;
  }

  .span-width {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 50px);
    font-size: 26px;
    font-weight: 600;
    color: #052e54;
    width: 100px;
    line-height: 32px;
    text-align: center;
  }

  /deep/.el-progress-bar__innerText {
    color: #fff !important;
  }
</style>
