<template>
  <el-dialog v-if="visiableStock" title="对公转账审核处理" :visible.sync="visiableStock" :close-on-click-modal="false"
    width="1400px" @close="back" append-to-body>
    <div style="padding: 40px 100px;">
      <div>
        <h3>1.充值订单信息</h3>
        <div class="stockInfo">
          <div style="display: flex;flex-direction: column;">
            <span>
              用户姓名：{{propFrom.payAccount}}
            </span>
            <span>
              实付金额：<span style="color: red;">{{propFrom.amount}}</span>元
            </span>
            <span>创建时间：{{propFrom.createTime}}</span>
          </div>
          <div style="display: flex;flex-direction: column;">
            <span>
              充值订单号：{{propFrom.orderNumber}}
            </span>
            <span>
              过期时间：{{propFrom.expiredTime}}
            </span>
          </div>
          <div style="display: flex;flex-direction: column;">
            <span>
              支付状态：<span style="color:yellow">{{payList.filter(item=>item.value == propFrom.status)[0].label }}</span>
            </span>
            <span>
              是否过期：<span style="color:yellow">{{propFrom.orderStatus == 0 ? '未过期' : (propFrom.orderStatus == 1 ? '已过期' : '未知')}}</span>
            </span>
          </div>
        </div>
      </div>
      <div>
        <h3>2.转账凭证信息及收款银行信息</h3>
        <div style="display: flex;justify-content: space-between;line-height: 40px;padding-left: 10px;">
          <div style="width: 40%;">
            <div>转账识别码：{{propFrom.transferIdentificationCode}}</div>
            <div style="display: flex;">
              <div style="width: 200px;">转账单号/交易流水号</div>
              <div
                style="border: 1px solid grey;border-radius: 5px;width: 90%;height: 30px;display: flex;align-items: center;margin-left: 10px;padding: 0px 5px;">
                {{propFrom.payNumber}}
              </div>
            </div>
            <div style="display: flex;">
              <div style="width: 200px;">电子回单</div>
              <el-image :src="propFrom.electronicReceipt" style="width:350px ;" fit="cover" />
            </div>
          </div>
          <div style="width: 50%;border: 1px solid grey;padding: 20px;border-radius: 5px;height: 210px;">
            <div>
              <span>收款银行信息：</span>
              <div style="line-height: 30px;">
                <div style="display: flex;width: 100%;align-items: center;">
                  <span style="width: 150px;">收款人户名</span>
                  <div>
                    <span>{{forms.amountName}}</span>
                    <el-button type="text" style="margin: 0px 15px;"
                      @click="copy(forms.amountName)">复制</el-button>
                  </div>
                </div>
                <div style="display: flex;width: 100%;align-items: center;">
                  <span style="width: 150px;">开户银行</span>
                  <div>
                    <span>{{forms.bank}}</span>
                    <el-button type="text" style="margin: 0px 15px;"
                      @click="copy(forms.bank)">复制</el-button>
                  </div>
                </div>
                <div style="display: flex;width: 100%;align-items: center;">
                  <span style="width: 150px;">银行卡号</span>
                  <div>
                    <span>{{forms.bankNumber}}</span>
                    <el-button type="text" style="margin: 0px 15px;"
                      @click="copy(forms.bankNumber)">复制</el-button>
                  </div>
                </div>
                <div style="display: flex;width: 100%;align-items: center;">
                  <span style="width: 150px;">用途/备注/摘要栏</span>
                  <div>
                    <span>{{propFrom.transferIdentificationCode}}</span>
                    <el-button type="text" style="margin: 0px 15px;"
                      @click="copy(propFrom.transferIdentificationCode)">复制</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="back">取消</el-button>
      <el-button type="primary" @click="checksrue">确认收到款项</el-button>
      <el-button type="danger" @click="checkFail" :disabled="(propFrom.status != 0 || propFrom.orderStatus != 1 )&& propFrom.status != 1 ">未收到款项不通过</el-button>
    </span>
    <Receive v-if="ReceiveVisible" @close="stockback" :Id="propFrom.id" @sureStock="sureDate" />
    <not-receive v-if="notReceiveVisible" @close="stockback" :Id="propFrom.id" @sureStock="sureDate" />
  </el-dialog>
</template>

<script>
  import notReceive from './notReceive.vue'
  import Receive from './Receive.vue'
  export default {
    components: {
      Receive,
      notReceive
    },
    props: ['propFrom'],
    data() {
      return {
        visiableStock: true,
        ReceiveVisible: false,
        notReceiveVisible: false,
        forms:{
          amountName:'中泽信技术（深圳）有限公司',
          bank:'中国工商银行股份有限公司深圳侨香支行',
          bankNumber:'4000051309100245042'
        },
        payList: [{
          value: 0,
          label: '未支付'
        }, {
          value: 1,
          label: '待确认'
        }, {
          value: 2,
          label: '支付成功'
        }, {
          value: 3,
          label: '支付失败'
        }, {
          value: 4,
          label: '审核不通过'
        }, {
          value: 5,
          label: '超时关闭'
        }, {
          value: 6,
          label: '退款成功'
        }],
      }
    },
    created() {
    //   this.visiableStock = true
    },
    methods: {
      checksrue() {
        this.ReceiveVisible = true
      },
      checkFail() {
        this.notReceiveVisible = true
      },
      sureDate() {
        this.visiableStock = false
        this.$emit('stockClose')
      },
      back() {
        this.visiableStock = false
        this.$emit('close')
      },
      stockback() {
        this.ReceiveVisible = false
        this.notReceiveVisible = false
      },
      useCopyToClipboard(val) {
        let isSuccessRef = false;

        //创建input标签
        var input = document.createElement("input");
        //将input的值设置为需要复制的内容
        input.value = val;
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        if (document.execCommand("copy")) {
          isSuccessRef = true;
        } else {
          isSuccessRef = false;
        }
        //移除input标签
        document.body.removeChild(input);

        return {
          isSuccessRef
        };
      },
      copy(val) {
        let {
          isSuccessRef
        } = this.useCopyToClipboard(val)
        if (isSuccessRef) {
          this.$message({
            type: 'success',
            message: '复制成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: '复制失败'
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .stockInfo {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    padding-left: 10px;
  }
</style>
