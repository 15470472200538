<template>
	<div class="dioBg">
		<div class="dioBg-om">
			<el-card>
				<!-- <div class="carBox">
					<template v-if="seleTabOn==18">{{dioData.data.xcms}}</template>
					<template v-else-if="seleTabOn==26||seleTabOn==27||seleTabOn==28||seleTabOn==4">
						<p>工时费用:{{dioData.data.gsfy}}元</p>
						<p>部件费用:{{dioData.data.bjfy}}元</p>
						<p>预计天数:{{dioData.data.yjts}}天</p>
					</template>
					<template
						v-else-if="seleTabOn==3||seleTabOn==22||seleTabOn==34||seleTabOn==10">{{dioData.data.memo}}</template>
					<template v-else-if="seleTabOn==5||seleTabOn==30||seleTabOn==32">{{dioData.data.bzxx}}</template>
				</div> -->

				<div class="carBox">
					<template v-if="seleTabOn==18">
						<div>
							{{dioData.data.xcms}}
						</div>
						<p>勘查人: {{dioData.data.userName}}</p>
						<p>确认时间: {{dioData.createTime}}</p>
					</template>
					<template v-else-if="seleTabOn==26||seleTabOn==27||seleTabOn==28||seleTabOn==4">
						<p>工时费用:{{dioData.data.gsfy}}元</p>
						<p>部件费用:{{dioData.data.bjfy}}元</p>
						<p>预计天数:{{dioData.data.yjts}}天</p>
						<p>费用合计:{{dioData.data.costCount}}元</p>
						<p>报价人: {{dioData.data.userName}}</p>
						<p>确认时间: {{dioData.createTime}}</p>
					</template>
					<template v-else-if="seleTabOn==3||seleTabOn==24">
						<div>
							{{dioData.data.memo}}
						</div>
						<p>方案提交人: {{dioData.data.userName}}</p>
						<p>确认时间: {{dioData.createTime}}</p>
					</template>
					<template v-else-if="seleTabOn==34">
						<div>
							{{dioData.data.memo}}
						</div>
						<p>付款人: {{dioData.data.userName}}</p>
						<p>确认时间: {{dioData.createTime}}</p>
					</template>
					<template v-else-if="seleTabOn==10">
						<div>
							{{dioData.data.memo}}
						</div>
						<p>确认时间: {{dioData.createTime}}</p>
					</template>
					<template v-else-if="seleTabOn==5||seleTabOn==30||seleTabOn==32">
						<div>
							{{dioData.data.bzxx}}
						</div>
					</template>
				</div>
			</el-card>
			<p class="hpan" v-if="seleTabOn==3||seleTabOn==18||seleTabOn==24">

				<!-- <template v-for="itm in imgLst">
					<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="imgLst">
					</el-image>
				</template> -->
				<template v-if="dioData.data.xzzp && dioData.data.xzzp.length > 0">
					<p class="msgDiv">现场照片</p>
					<p class="hpan" style="display: flex;">
						<template v-for="(itm, index) in imgLst">
							<div style="text-align: center; margin: 0 5px" :key="index" v-if="itm">
								<el-image class="gdImg" style="margin:0" :src="itm" fit="scale-down" :preview-src-list="imgLst">
								</el-image>
								<div style="margin-top: 5px">{{dioData.data.xzzp[index].type}}</div>
							</div>
						</template>
					</p>
				</template>

				<template v-if="dioData.data.jcgczp && dioData.data.jcgczp.length > 0">
					<p class="msgDiv">检查过程照片</p>
					<el-image class="gdImg" :src="jcgczp" fit="scale-down" :preview-src-list="dioData.data.jcgczp">
					</el-image>
				</template>

				<template v-if="dioData.data.shbjztzp && dioData.data.shbjztzp.length > 0">
					<p class="msgDiv">损坏部件整体照片</p>
					<el-image class="gdImg" :src="shbjztzp" fit="scale-down" :preview-src-list="dioData.data.shbjztzp">
					</el-image>
				</template>

				<template v-if="dioData.data.shbjxtzp && dioData.data.shbjxtzp.length > 0">
					<p class="msgDiv">损坏部件细节照片</p>
					<el-image class="gdImg" :src="shbjxtzp" fit="scale-down" :preview-src-list="dioData.data.shbjxtzp">
					</el-image>
				</template>

				<template v-if="dioData.data.qtzp && dioData.data.qtzp.length > 0">
					<p class="msgDiv">其他照片</p>
					<el-image class="gdImg" :src="qtzp" fit="scale-down" :preview-src-list="dioData.data.qtzp">
					</el-image>
				</template>
			</p>
			<template v-else-if="seleTabOn==34||seleTabOn==10">
				<template v-if="dioData.data.fkpz && dioData.data.fkpz.length > 0">
					<p class="msgDiv">付款凭证(纸质拍照,电子件截图)</p>
					<template v-for="itm in dioData.data.fkpz">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.fkpz">
						</el-image>
					</template>
				</template>
			</template>
			<template v-else-if="seleTabOn==26||seleTabOn==27||seleTabOn==28||seleTabOn==4">
				<template v-if="dioData.data.jcbg && dioData.data.jcbg.length > 0">
					<p class="msgDiv">检测报告</p>
					<template v-for="itm in dioData.data.jcbg">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.jcbg">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.wxbj && dioData.data.wxbj.length > 0">
					<p class="msgDiv">维修报价</p>
					<template v-for="itm in dioData.data.wxbj">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.wxbj">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.dsfjxbg && dioData.data.dsfjxbg.length > 0">
					<p class="msgDiv">第三方检修报告</p>
					<template v-for="itm in dioData.data.dsfjxbg">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.dsfjxbg">
						</el-image>
					</template>
				</template>
			</template>
			<template v-else-if="seleTabOn==5||seleTabOn==30||seleTabOn==32">
				<template v-if="dioData.data.qtfj && dioData.data.qtfj.length > 0">
					<p class="msgDiv">其他附件</p>
					<template v-for="itm in dioData.data.qtfj">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.qtfj">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.bjczgczp && dioData.data.bjczgczp.length > 0">
					<p class="msgDiv">部件拆装过程照片</p>
					<template v-for="itm in dioData.data.bjczgczp">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.bjczgczp">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.xjbjdbzp && dioData.data.xjbjdbzp.length > 0">
					<p class="msgDiv">新旧部件对比照片</p>
					<template v-for="itm in dioData.data.xjbjdbzp">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.xjbjdbzp">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.xjbjsfbmhzewmzp && dioData.data.xjbjsfbmhzewmzp.length > 0">
					<p class="msgDiv">新旧部件身份编码或者二维码照片</p>
					<template v-for="itm in dioData.data.xjbjsfbmhzewmzp">
						<el-image class="gdImg" :src="itm" fit="scale-down"
							:preview-src-list="dioData.data.xjbjsfbmhzewmzp">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.dsfdlbxxzp && dioData.data.dsfdlbxxzp.length > 0">
					<p class="msgDiv">第三方电路板维修照片</p>
					<template v-for="itm in dioData.data.dsfdlbxxzp">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.dsfdlbxxzp">
						</el-image>
					</template>
				</template>

				<template v-if="dioData.data.wxgczp && dioData.data.wxgczp.length > 0">
					<p class="msgDiv">维修过程照片</p>
					<template v-for="itm in dioData.data.wxgczp">
						<el-image class="gdImg" :src="itm" fit="scale-down" :preview-src-list="dioData.data.wxgczp">
						</el-image>
					</template>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['dioData', 'seleTabOn'],
		data() {
			return {
				imgLst: [],
				shbjztzp: '',
				shbjxtzp: '',
				jcgczp: '',
				qtzp: ''
			}
		},
		created() {
			let obj = this.dioData.data
			// this.imgLst = [...obj.shbjztzp ?? [], ...obj.shbjxtzp ?? [], ...obj.qtzp ?? [], ...obj.jcbg ?? [], ...obj
			// 	.wxbj ?? [], ...obj.dsfjxbg ?? [], ...obj
			// 	.qtfj ?? [], ...obj.bjczgczp ?? [], ...obj.xjbjdbzp ?? [], ...obj.xjbjsfbmhzewmzp ?? [], ...obj
			// 	.dsfdlbxxzp ?? [], ...obj.wxgczp ?? [], ...
			// 	obj.qm ?? [], ...obj.fkpz ?? []
			// ]
			this.shbjztzp = this.dioData.data.shbjztzp ? this.dioData.data.shbjztzp[0] : ''
			this.shbjxtzp = this.dioData.data.shbjxtzp ? this.dioData.data.shbjxtzp[0] : ''
			this.jcgczp = this.dioData.data.jcgczp ? this.dioData.data.jcgczp[0] : ''
			this.qtzp = this.dioData.data.qtzp ? this.dioData.data.qtzp[0] : ''
			if (obj.xzzp) {
				obj.xzzp.forEach((qs) => {
					this.imgLst.push(qs.url)
				})
			}
		}
	}
</script>

<style scoped>
	.gdImg {
		width: 100px;
		height: 100px;
		margin-right: 10px;
		margin-top: 10px;
	}

	.carBox {
		width: 100%;
		height: 200px;
	}

	.hpan {
		margin-right: 5px;
	}

	.dioBg-om {
		width: 100%;
		margin-right: 2%;
	}

	.dioBg {
		width: 98%;
		height: 100%;
		display: flex;
	}
</style>
