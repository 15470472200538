<template>
  <div style="padding: 5px; background-color: #3d7796; height: 100%">
    <!-- background-color: #1288a9; -->
    <div class="floor-info">
      <span style="color: white">楼层信息</span>
      <el-button type="text" style="font-size: 16px" @click="close"
        >关闭
      </el-button>
    </div>
    <el-table :data="list" v-loading="tableLoading" height="350px">
      <el-table-column label="序号" type="index" />
      <el-table-column label="默认楼层名称" prop="floor" align="center" />
      <el-table-column label="海拔高度" prop="altitudeHeight" align="center" />
      <el-table-column label="楼层高度" prop="height" align="center" />
      <el-table-column label="楼层别名" prop="floorName" align="center">
        <template slot-scope="scope">
          <span
            v-if="floorType && scope.row.floorName != ''"
            @click="cellClick(scope.$index, scope.row, scope.column)"
          >
            {{ scope.row.floorName }}
          </span>
          <el-input
            v-else
            :ref="'floorNameInput_' + scope.$index"
            placeholder="请输入楼层别名"
            v-model="scope.row.floorName"
            @input="changeFloor(scope.$index, scope.row)"
            @blur="blurFloorName(scope.$index, scope.row, scope.column)"
          >
          </el-input>
        </template>
      </el-table-column>
    </el-table>
    <div slot="right-footer" class="table-footer">
      <el-button
        type="text"
        style="color: #f00 !important"
        @click="errorInfo(2)"
        >信息不准确
      </el-button>
      <el-button type="text" style="margin-right: 15px" @click="errorInfo(1)"
        >信息准确
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["elevatorId", "deviceCode", "floorId"],
  data() {
    return {
      list: [],
      infoData: [],
      heights: [],
      floorType: true,
      floorAlias: [],
      isEdit: false,
      tableLoading: true
    };
  },
  methods: {
    //关闭
    close() {
      this.$emit("close");
    },
    getDate() {
      this.tableLoading = true
      this.$http
        .get(`/api/ele/web/floor/getFloorDetail?id=${this.floorId}`)
        .then((res) => {
          if (res.status == 200 && res.data.success) {
            //别名
            this.floorAlias = res.data.data.floorAlias
              ? JSON.parse(res.data.data.floorAlias).data
              : [];
            //层高
            const heightMap = res.data.data.heightMap
              ? JSON.parse(res.data.data.heightMap).data
              : [];
            //海拔
            const floorHeight =
              res.data.data.floorHeight != ""
                ? JSON.parse(res.data.data.floorHeight).data
                : [];
            this.list = heightMap;
            floorHeight.forEach((item, index) => {
              this.list[index].altitudeHeight = item.height;
              this.list[index].index = index;
            });
            if (this.floorAlias && this.floorAlias.length > 0) {
              this.floorAlias.forEach((item, index) => {
                this.list[index].floorName = item.name;
                this.list[index].index = index;
              });
            } else {
              this.list.forEach((item, index) => {
                item.floorName = "";
              });
            }
          } else {
            this.$message.error(res.data.msg);
          }
          this.tableLoading = false
        });
    },
    cellClick(index, row, column) {
      this.floorType = !this.floorType;
      this.$set(this.list, index, row);
      this.$nextTick(() => {
        this.$refs["floorNameInput_" + row.index].focus();
      });
    },
    // 修改楼层别名
    changeFloor(index, row) {
      if (
        this.list[index].floorName &&
        !/^[\u4E00-\u9FA5a-zA-Z0-9&-]{0,10}$/.test(this.list[index].floorName)
      ) {
        this.$message.error(
          "可输入中文，数字，大小写字母，长度不可超过10，不可输入特殊字符"
        );
        return;
      }
      this.floorType = false
      const list = this.list[index];
      this.isEdit = this.floorAlias[index] ? this.list[index].floorName != this.floorAlias[index].floorName : true
      this.$set(this.list, index, { ...list });
      this.$set(this.floorAlias, index, { ...list });
    },
    blurFloorName(index, row, column) {
      this.floorType = !this.floorType;
      if (
        this.list[index].floorName &&
        !/^[\u4E00-\u9FA5a-zA-Z0-9&-]{0,10}$/.test(this.list[index].floorName)
      ) {
        this.list[index].floorName = this.floorAlias[index].floorName
        return;
      }
      const list = this.list[index];
      this.$set(this.list, index, list);
    },
    updateList() {
      const floorAlias = [];
      this.list.forEach((qs, index) => {
        floorAlias.push({
          name: qs.floorName ? qs.floorName : "",
          floor: qs.floor ? qs.floor : "",
        });
      });
      const data = {
        id: this.floorId,
        floorAlias: JSON.stringify({
          data: floorAlias,
        }),
      };
      this.$http.post("/api/ele/web/floor/edit", data).then((res) => {
        if (res.data.success) {
          this.sendSoket();
        }
      });
    },
    sendSoket(){
      const agentData = {
        act: "device_restart",
        device_id: this.deviceCode,
      };
      this.socketApi.sendSock(agentData, (e) => {
        if (e.cmd === "device_restart" && e.status) {
          this.$message.success("稍后球机自动重启！");
        } else {
          this.$message.error(e.msg);
        }
      });
    },
    //信息检验
    errorInfo(type) {
      if (this.isEdit) {
        this.updateList()
      }
      this.infoData = {
        id: this.floorId,
        examineStatus: type,
        elevatorId: this.elevatorId,
      };
      this.$http
        .post("/api/ele/web/floor/examineFloorLog", this.infoData)
        .then((res) => {
          if (res.data.success) {
            this.$message.success({
              type: "success",
              message: res.data.msg,
            });
            if (type == 2) {
              this.sendSoket();
            }

            this.$emit("close");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
  mounted() {
    this.getDate();
  },
};
</script>

<style scoped>
.floor-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
}

.table-scroll {
  height: 370px;
  overflow-y: scroll;
}

.table-footer {
  /* float: right;
    margin: 20px 0px 10px 0px; */
  display: flex;
  flex-direction: row-reverse;
}
</style>
