module.exports = {
	language: {
		name: '中文'
	},
	homes: {
		home: 'home',
		operate: 'operate',
		commodity: 'commodity',
		extension: 'extension',
		system: 'system',

		order: 'Omanagement',
		comment: 'comment',
		freight: 'freight',
		
		goodsredact: 'Csredact',
		management: 'Cmanagement',
		classify: 'Ccategory',

		shop: 'shop',
		recommend: 'recommend',
		discounts: 'discounts',
		shopmessage: 'shopMessage',

		member: 'memberManagement',
		Administrator: 'Administrator',
		role: 'role management',

		login: 'login'
	}
}
